'use client'
import React, { useState } from 'react'
import UpArrow from './UpArrow'
import DownArrow from './DownArrow'
import Remove from './Remove'
import UploadImage from './UploadImage'

const CompanyLogo = ({ index, initialValues, onDataChange }) => {
    const [formData, setFormData] = useState({
        image: initialValues?.image || "",
        image_public_id: initialValues?.image_public_id || "",
        title: initialValues?.title || ''
    });

    const handleFieldChange = (field, value) => {
        let updatedFormData;
        updatedFormData = { ...formData, [field]: value };
        setFormData(updatedFormData);
        onDataChange(updatedFormData);
    };

    return (
        <div
            id={`standard_company_logo_${index}}`}
            className='w-full flex flex-col border'>
            <div className='flex justify-between items-center border pl-4'>
                <div>
                    <h1 className='text-xl text-black font-semibold '>Standard Company Logo </h1>
                </div>
                <div className='flex' >
                    <UpArrow index={index} />
                    <DownArrow index={index} />
                    <Remove index={index} />
                </div>
            </div>


            <div className='mt-5 p-2'>

                <UploadImage
                    id={`standard-company-logo-${index}`}
                    height={`h-60`}
                    name={`image`}
                    aspectRatio={970 / 180}
                    requiredRatio={`970 / 180`}
                    tolerance={0.1}
                    value={formData?.image}
                    onChange={handleFieldChange}
                />
            </div>

        </div>
    )
}

export default CompanyLogo;