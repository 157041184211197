import React, { useState, useEffect } from "react";
import axios from "axios";
import { getToken } from "../../../hook/getToken";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";

const CompanyDetails = ({ onDataChange, goToNextTab }) => {
  const { id } = useParams();
  console.log('id', id) 
  const [companyData, setCompanyData] = useState({
    company: "",
    founderName: "",
    city: "",
    country: "",
    website: "",
    contact: "",
    email: "",
    instagramLink: "",
    companyLogo: null,
    companyLogoUrl: "",
    companyDescription: "",
    _id: "",
  });

  const [companyType, setCompanyType] = useState("new");
  const [existingCompanies, setExistingCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      

      try {
        const accessToken = getToken();

        const response = await axios.get(
          `${process.env.REACT_APP_URL}/v1/pre-order/get-tab-completion?preorder_session_id=${id}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        const data = response.data;
        if (data.success) {
          const updatedData = {
            company: data.companyData.name,
            founderName: data.companyData.founder_name,
            city: data.companyData.city,
            country: data.companyData.country,
            website: data.companyData.website,
            contact: data.companyData.contact,
            email: data.companyData.email,
            instagramLink: data.companyData.instagram_link,
            companyLogo: null,
            companyLogoUrl: data.companyData.logo,
            companyDescription: data.companyData.description,
            _id: data.companyData._id,
          };
          setCompanyData(updatedData);
          onDataChange(updatedData);
          setIsDisabled(true);
        }
      } catch (error) {
        console.error("Error fetching data from API:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (companyType === "existing") {
      fetchExistingCompanies();
    }
  }, [companyType]);

  const fetchExistingCompanies = async () => {
    try {
      const accessToken = getToken();
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/v1/pre-order/get-company-list`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      setExistingCompanies(response.data.response);
    } catch (error) {
      console.error("Error fetching company list:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedData = { ...companyData, [name]: value };
    setCompanyData(updatedData);
    onDataChange(updatedData);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const updatedData = {
        ...companyData,
        companyLogo: file,
        companyLogoUrl: URL.createObjectURL(file),
      };
      setCompanyData(updatedData);
      onDataChange(updatedData);
    }
  };

  const handleCompanyTypeChange = (e) => {
    setCompanyType(e.target.value);
    if (e.target.value === "new") {
      const newCompanyData = {
        company: "",
        founderName: "",
        city: "",
        country: "",
        website: "",
        contact: "",
        email: "",
        instagramLink: "",
        companyLogo: null,
        companyLogoUrl: "",
        companyDescription: "",
        _id: "",
      };
      setCompanyData(newCompanyData);
      setSelectedCompany(null);
      onDataChange(newCompanyData);
    }
  };

  const handleExistingCompanySelect = async (e) => {
    const companyId = e.target.value;
    setSelectedCompany(companyId);

    if (companyId) {
      try {
        const accessToken = getToken();
        const response = await axios.get(
          `${process.env.REACT_APP_URL}/v1/pre-order/get-company?company_id=${companyId}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        const company = response.data.response;
        const updatedData = {
          company: company.name,
          founderName: company.founder_name || "",
          city: company.city || "",
          country: company.country || "",
          website: company.website || "",
          contact: company.contact || "",
          email: company.email || "",
          instagramLink: company.instagram_link || "",
          companyLogo: null,
          companyLogoUrl: company.logo || "",
          companyDescription: company.description || "",
          _id: company._id,
        };
        setCompanyData(updatedData);
        onDataChange(updatedData);
      } catch (error) {
        console.error("Error fetching company details:", error);
      }
    }
  };

  const handleSubmit = async (actionType, goToNextTab) => {
    try {
      let logoData = null;
      if (companyData.companyLogo) {
        logoData = await fileToDataUri(companyData.companyLogo);
      } else {
        logoData = companyData.companyLogoUrl;
      }

      const requestBody = {
        name: companyData.company,
        founder_name: companyData.founderName,
        city: companyData.city,
        country: companyData.country,
        website: companyData.website,
        contact: companyData.contact,
        email: companyData.email,
        instagram_link: companyData.instagramLink,
        logo: logoData,
        description: companyData.companyDescription,
      };

      if (companyType === "existing" && companyData._id) {
        requestBody.company_id = companyData._id; // Add company_id field only for existing companies
      }

      const accessToken = getToken();

     
      let apiUrl = `${process.env.REACT_APP_URL}/v1/pre-order/add-company`;
      if (id) {
        apiUrl += `?preorder_session_id=${id}`;
      }

      const response = await axios.post(apiUrl, requestBody, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      });

      toast.success("Company added successfully:", response.data);

      if (response.data.preorder_session_id) {
        localStorage.setItem(
          "preorder_session_id",
          response.data.preorder_session_id
        );
      }

      if (actionType === "next" && typeof goToNextTab === "function") {
        goToNextTab();
      }
    } catch (error) {
      console.error("Error adding company:", error);
    }
  };

  const fileToDataUri = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        resolve(event.target.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  return (
    <div className="p-4 bg-white rounded-lg shadow-md text-left">
      <h2 className="text-xl font-semibold mb-4 text-blue-600">
        Company Details
      </h2>
      <form className="grid grid-cols-2 gap-4">
        <div className="col-span-2 flex items-center space-x-6 mb-4">
          <label className="inline-flex items-center">
            <input
              type="radio"
              name="companyType"
              value="existing"
              className="form-radio"
              checked={companyType === "existing"}
              onChange={handleCompanyTypeChange}
              disabled={isDisabled}
            />
            <span className="ml-2">Existing Company</span>
          </label>
          <label className="inline-flex items-center">
            <input
              type="radio"
              name="companyType"
              value="new"
              className="form-radio"
              checked={companyType === "new"}
              onChange={handleCompanyTypeChange}
              disabled={isDisabled}
            />
            <span className="ml-2">Add a New Company</span>
          </label>
        </div>

        <div className="col-span-2 grid grid-cols-4 gap-4 mb-4">
          <div className="col-span-1">
            <label className="block text-sm font-medium mb-1">Company:</label>
            {companyType === "existing" ? (
              <select
                name="company"
                value={selectedCompany || ""}
                onChange={handleExistingCompanySelect}
                className="w-full p-2 border border-gray-300 rounded text-sm text-left"
                disabled={isDisabled}
              >
                <option value="">Select a company</option>
                {existingCompanies.map((company) => (
                  <option key={company._id} value={company._id}>
                    {company.name}
                  </option>
                ))}
              </select>
            ) : (
              <input
                type="text"
                name="company"
                value={companyData.company}
                onChange={handleChange}
                className="w-full p-2 border border-gray-300 rounded text-sm text-left"
                disabled={isDisabled}
              />
            )}
          </div>
          <div className="col-span-1">
            <label className="block text-sm font-medium mb-1">
              Founder Name:
            </label>
            <input
              type="text"
              name="founderName"
              value={companyData.founderName}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded text-sm text-left"
            />
          </div>
          <div className="col-span-1">
            <label className="block text-sm font-medium mb-1">City:</label>
            <input
              type="text"
              name="city"
              value={companyData.city}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded text-sm text-left"
            />
          </div>
          <div className="col-span-1">
            <label className="block text-sm font-medium mb-1">Country:</label>
            <input
              type="text"
              name="country"
              value={companyData.country}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded text-sm text-left"
            />
          </div>
        </div>
        <div className="col-span-2 grid grid-cols-4 gap-4 mb-4">
          <div className="col-span-1">
            <label className="block text-sm font-medium mb-1">Website:</label>
            <input
              type="text"
              name="website"
              value={companyData.website}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded text-sm text-left"
            />
          </div>
          <div className="col-span-1">
            <label className="block text-sm font-medium mb-1">Contact:</label>
            <input
              type="text"
              name="contact"
              value={companyData.contact}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded text-sm text-left"
            />
          </div>
          <div className="col-span-1">
            <label className="block text-sm font-medium mb-1">Email:</label>
            <input
              type="text"
              name="email"
              value={companyData.email}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded text-sm text-left"
            />
          </div>
          <div className="col-span-1">
            <label className="block text-sm font-medium mb-1">
              Instagram Link:
            </label>
            <input
              type="text"
              name="instagramLink"
              value={companyData.instagramLink}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded text-sm text-left"
            />
          </div>
          {/* <div className="col-span-1 mb-4">
          <label className="block text-sm font-medium mb-1">
            Funding Goal:
          </label>
          <input
            type="number"
            name="funding_goal"
            value={companyData.funding_goal}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded text-sm text-left"
          />
        </div> */}
          
        </div>
        <div className="col-span-2 mb-4 flex flex-col justify-start items-start">
          <label className="block text-sm font-medium mb-1 mr-4">
            Company Logo (1:1):
          </label>
          {/* {companyData.companyLogoUrl && (
            <img
              src={companyData.companyLogoUrl}
              alt="Company Logo Preview"
              className="w-1/4 mb-2"
            />
          )} */}
          <div
            className="w-1/4 p-2 border border-gray-300 rounded text-sm text-left cursor-pointer"
            onClick={() => document.getElementById("companyLogoInput").click()}
          >
            {companyData.companyLogoUrl ? (
              <img
                src={companyData.companyLogoUrl}
                alt="Company Logo Preview"
                className="w-full h-72"
              />
            ) : (
              <div className=" h-72 flex items-center justify-center">
                Click to select image
              </div>
            )}
          </div>
          <input
            id="companyLogoInput"
            type="file"
            name="companyLogo"
            onChange={handleFileChange}
            className="hidden"
          />
        </div>
        <div className="col-span-2 mb-4">
          <label className="block text-sm font-medium mb-1">
            Company Description:
          </label>
          <textarea
            name="companyDescription"
            value={companyData.companyDescription}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded text-sm text-left"
          />
        </div>
       
        <div className="col-span-2 text-right">
          <button
            type="button"
            className="px-4 py-2 bg-gray-500 text-white rounded text-sm"
            onClick={() => handleSubmit("draft")}
          >
            Save as Draft
          </button>
          <button
            type="button"
            className="ml-4 px-4 py-2 bg-blue-500 text-white rounded text-sm"
            onClick={() => handleSubmit("next", goToNextTab)}
          >
            Save & Next
          </button>
        </div>
      </form>
    </div>
  );
};

export default CompanyDetails;