import { useState, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const SliderImages = ({
  images,
  setImages,
  brands,
  products,
  setProducts,
  handleImageSelectionChange,
  handleRemoveImage,
}) => {
  const [searchQueries, setSearchQueries] = useState({});
  const [showProductList, setShowProductList] = useState({});

  // Reordering function
  const handleDragEnd = (result) => {
    if (!result.destination) return; // If dropped outside the list, do nothing

    const reorderedImages = [...images];
    const [movedImage] = reorderedImages.splice(result.source.index, 1);
    reorderedImages.splice(result.destination.index, 0, movedImage);

    setImages(reorderedImages);
  };

  const handleSearchChange = (index, value) => {
    setSearchQueries((prev) => ({ ...prev, [index]: value }));
    setShowProductList((prev) => ({ ...prev, [index]: true }));
  };

  const handleProductSelection = (index, type, product) => {
    handleImageSelectionChange(index, type, product._id);

    setSearchQueries((prev) => ({ ...prev, [index]: product.name }));
    setShowProductList((prev) => ({ ...prev, [index]: false }));
  };
  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="imagesList">
        {(provided) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            className="h-56 overflow-y-auto"
          >
            {images.map((img, index) => (
              <Draggable
                key={index}
                draggableId={index.toString()}
                index={index}
              >
                {(provided) => (
                  <div
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                    className="flex items-center gap-4 mt-2 bg-white p-2 shadow-md rounded-md cursor-move"
                  >
                    <img
                      src={img.image}
                      alt="Slider Image"
                      className="h-24 w-24 object-cover"
                    />
                    <div className="flex flex-col gap-2">
                      {/* Product / Brand Selection */}
                      <div className="flex gap-2">
                        <label>
                          <input
                            type="radio"
                            name={`selection-${index}`}
                            value="product"
                            checked={!!img.product_id}
                            onChange={() =>
                              handleImageSelectionChange(
                                index,
                                "product_selected",
                                true
                              )
                            }
                          />
                          Product
                        </label>
                        <label>
                          <input
                            type="radio"
                            name={`selection-${index}`}
                            value="brand"
                            checked={!!img.brand_id}
                            onChange={() =>
                              handleImageSelectionChange(
                                index,
                                "brand_selected",
                                true
                              )
                            }
                          />
                          Brand
                        </label>
                      </div>
                      {/* Search Input for Products */}
                      {img.product_selected && (
                        <div>
                          <input
                            type="text"
                            className="p-2 border border-gray-300 rounded-lg text-xs w-full"
                            placeholder="Search Products"
                            value={
                              searchQueries[index] || img.product_name || ""
                            }
                            onChange={(e) =>
                              handleSearchChange(index, e.target.value)
                            }
                          />

                          {/* Display filtered products */}
                          {showProductList[index] &&
                            products[index]?.length > 0 && (
                              <ul className="mt-2 border border-gray-200 rounded-lg max-h-40 overflow-y-auto">
                                {products[index].map((product) => (
                                  <li
                                    key={product._id}
                                    className="p-2 cursor-pointer hover:bg-gray-100 z-10"
                                    onClick={() =>
                                      handleProductSelection(
                                        index,
                                        "product_id",
                                        product
                                      )
                                    }
                                  >
                                    {product.name}
                                  </li>
                                ))}
                              </ul>
                            )}

                          {/* Show no products found message if applicable */}
                          {searchQueries[index]?.length > 2 &&
                            products[index]?.length === 0 && (
                              <p className="text-xs text-gray-500 mt-2">
                                No products found.
                              </p>
                            )}
                        </div>
                      )}

                      {/* Dropdown for Brands */}
                      {img.brand_selected && (
                        <select
                          className="p-2 border border-gray-300 rounded-lg text-xs"
                          value={img._id || ""}
                          onChange={(e) =>
                            handleImageSelectionChange(
                              index,
                              "brand_id",
                              e.target.value
                            )
                          }
                        >
                          <option value="">Select Brand</option>
                          {brands.map((brand) => (
                            <option key={brand._id} value={brand.slug}>
                              {brand.brand_name}
                            </option>
                          ))}
                        </select>
                      )}

                      {/* Remove Button */}
                      <button
                        onClick={() => handleRemoveImage(index)}
                        className="text-red-600 text-xs mt-2"
                      >
                        Remove Image
                      </button>
                    </div>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default SliderImages;
