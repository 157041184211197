import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const modules = {
    toolbar: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],  // Include headings from h1 to h6
        ['bold', 'italic', 'underline', 'strike'],  // Text styles
        [{ list: 'ordered' }, { list: 'bullet' }], // Lists
        [{ script: 'sub' }, { script: 'super' }],  // Subscript / Superscript
        [{ indent: '-1' }, { indent: '+1' }],      // Indentation
        [{ direction: 'rtl' }],                    // Text direction
        [{ size: ['small', false, 'large', 'huge'] }],  // Font size
        [{ color: [] }, { background: [] }],       // Colors
        [{ font: [] }],                            // Font family
        [{ align: [] }],                           // Text alignment
        ['link', 'image', 'video'],                // Media
        ['clean']                                  // Remove formatting
    ],
};

const QuillEditor = ({ value, onChange }) => {
    return (
        <ReactQuill
            value={value}
            onChange={onChange}
            modules={modules}
        />
    );
};

export default QuillEditor;
