
import React, { useState, useEffect } from 'react'
import toast from 'react-hot-toast'
import { getToken } from '../../../../hook/getToken'
// import { useRouter } from 'next/navigation'
// import { useCms } from '@/context/CmsProvider'
import { useParams, useNavigate } from 'react-router-dom'
import TableRow from './TableRow'
const ManagerList = () => {
    const { id } = useParams()
    const navigate = useNavigate()
    const [data, setData] = useState({})
    useEffect(() => {

        const apiUrl = `${process.env.REACT_APP_URL}/v1/pre-order/get-cms-list?preorder_session_id=${id}`;

        // Replace 'YOUR_BEAproductId, variationIdRER_TOKEN' with your actual bearer token


        fetch(apiUrl, {
            method: 'GET',
            // headers: {
            //     'Authorization': `Bearer ${getToken()}`
            // }
        })
            .then(res => res.json())
            .then(data => {
                console.log('list data', data);
                setData(data.response)
            })
            .catch(error => console.error('Error:', error));

    }, [])

    const handleCreate = (sessionId) => {
        if (data) {
            toast.error('A+ content already exist')
        } else {
            const redirectUrl = `/preorder/cms/add-edit/${sessionId}`
            navigate(redirectUrl)
        }
    }
    console.log('content data', data);
    return (
        <main className='flex flex-col m-5'>
            <div className='flex flex-row justify-between items-center'>
                <div >
                    <h1 className='text-xl text-gray-900 font-medium py-2'>Content Manager</h1>
                </div>
                <button
                    onClick={() => handleCreate(id)}
                    type='button' className={`${data ? 'opacity-50 ' : 'opacity-100 '} h-fit py-1 px-4 rounded bg-teal-500 text-white`}>Start Creating Landing Page
                </button>
            </div>
            <section className='flex flex-col border border-solid border-[#EEE] mt-10'>
                <div className='flex flex-col p-5 bg-gray-50'>
                    <h6>Content List</h6>
                </div>

                {/* Table Section */}
                <div className='relative overflow-hidden'>
                    <table className="w-full text-left text-xs">
                        <thead className="bg-gray-50 text-xs font-medium  text-[#666666]">
                            <tr>
                                <th scope="col" className="px-6 py-3">
                                    content name
                                </th>

                                <th scope="col" className="px-6 py-3">
                                    last modified
                                </th>
                                <th scope="col" className="px-6 py-3">content status</th>
                                <th scope="col" className="px-6 py-3">action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                data ?
                                    <TableRow
                                        data={data}
                                        sessionId={id}
                                    /> :
                                    <tr>
                                        <td col='4'>No Content</td>
                                    </tr>
                            }


                        </tbody>
                    </table>

                </div>
                {/* <div className='flex justify-end items-center py-5 mx-5'>
                    <Pagination
                        currentPage={currentPage}
                        totalItems={totalItems}
                        pageSize={pageSize}
                        setCurrentPage={setCurrentPage}
                    />
                </div> */}
            </section>

        </main>

    )
}

export default ManagerList