import React, { useState, useEffect } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";

const TimeLine = () => {
  const {id} = useParams();
  const preorder_session_id = localStorage.getItem("preorder_session_id");
  const sessionId = id || preorder_session_id; // Use id from URL or fallback to localStorage
  const [timeline, setTimeline] = useState([
    { id: 1, day: "", month: "", year: "", label: "Showcase", description: "" },
    { id: 2, day: "", month: "", year: "", label: "Official Sale", description: "" },
    { id: 3, day: "", month: "", year: "", label: "Target", description: "" },
    { id: 4, day: "", month: "", year: "", label: "Expected Delivery", description: "" },
  ]);

 
  const accessToken = localStorage.getItem("access_token");

  useEffect(() => {
    const fetchTimelineData = async () => {
      try {
     
        const response = await axios.get(
          `${process.env.REACT_APP_URL}/v1/pre-order/get-preorder-timeline?preorder_session_id=${sessionId}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "Content-Type": "application/json",
            },
          }
        );

        const fetchedData = response.data.response.timeline_data.map(
          (item, index) => {
            const date = new Date(item.date);
            const day = date.getDate().toString().padStart(2, "0");
            const month = (date.getMonth() + 1).toString().padStart(2, "0");
            const year = date.getFullYear().toString();
            return {
              id: index + 1,
              day,
              month,
              year,
              label: item.label,
              description: item.description,
            };
          }
        );

        setTimeline(fetchedData);
      } catch (error) {
        console.error("Error fetching timeline data:", error);
      }
    };

    fetchTimelineData();
  }, [id, preorder_session_id, accessToken]);

  const handleAddItem = () => {
    setTimeline([
      ...timeline,
      {
        id: timeline.length + 1,
        day: "",
        month: "",
        year: "",
        label: "",
        description: "",
      },
    ]);
  };

  const handleRemoveItem = (id) => {
    if (timeline.length > 1) {
      setTimeline(timeline.filter((item) => item.id !== id));
    }
  };

  const handleChange = (e, id, field) => {
    const updatedTimeline = timeline.map((item) =>
      item.id === id ? { ...item, [field]: e.target.value } : item
    );
    setTimeline(updatedTimeline);
  };

  const handleSubmit = async (status) => {
    if (timeline.length === 0) {
      toast.error("At least one timeline item is required.");
      return;
    }

    const timelineData = timeline.map((item) => {
      const formattedDate = `${item.month}/${item.day}/${item.year}`;
      return {
        date: formattedDate,
        label: item.label,
        description: item.description,
      };
    });

    const apiBody = {
      preorder_session_id: sessionId,
      timeline_data: timelineData,
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/v1/pre-order/add-preorder-timeline/?status=${status}`, // Add status as a query parameter
        apiBody,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status == '200') {
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred while submitting the form.");
    }
  };

  return (
    <div className="p-4">
      <h2 className="text-xl font-semibold mb-6 text-left text-blue-600">
        Time Line
      </h2>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit("active");
        }}
      >
        {timeline.map((item) => (
          <div key={item.id} className="mb-4 p-4 text-xs">
            <div className="grid grid-cols-8 gap-4 items-end">
              <div className="col-span-2">
                <label className="block font-medium text-gray-700 text-left">
                  Label:
                </label>
                <select
                  className="py-1 px-2 border rounded w-full text-left"
                  value={item.label}
                  onChange={(e) => handleChange(e, item.id, "label")}
                  disabled
                >
                  <option value="">Select Label</option>
                  <option value="Showcase">Showcase</option>
                  <option value="Official Sale">Official Sale</option>
                  <option value="Target">Target</option>
                  <option value="Expected Delivery">Expected Delivery</option>
                </select>
              </div>
              <div className="col-span-1">
                <label className="block font-medium text-gray-700 text-left">
                  Day:
                </label>
                <input
                  type="text"
                  placeholder="DD"
                  className="py-1 px-2 border rounded w-full text-left"
                  value={item.day}
                  onChange={(e) => handleChange(e, item.id, "day")}
                  required
                />
              </div>
              <div className="col-span-1">
                <label className="block font-medium text-gray-700 text-left">
                  Month:
                </label>
                <input
                  type="text"
                  placeholder="MM"
                  className="py-1 px-2 border rounded w-full text-left"
                  value={item.month}
                  onChange={(e) => handleChange(e, item.id, "month")}
                  required
                />
              </div>
              <div className="col-span-1">
                <label className="block font-medium text-gray-700 text-left">
                  Year:
                </label>
                <input
                  type="text"
                  placeholder="YYYY"
                  className="py-1 px-2 border rounded w-full text-left"
                  value={item.year}
                  onChange={(e) => handleChange(e, item.id, "year")}
                  required
                />
              </div>

              <div className="col-span-2">
                <label className="block font-medium text-gray-700 text-left">
                  Description:
                </label>
                <input
                  type="text"
                  placeholder="Descriptive point"
                  className="py-1 px-2 border rounded w-full text-left"
                  value={item.description}
                  onChange={(e) => handleChange(e, item.id, "description")}
                  required
                />
              </div>
              {/* <div className="col-span-1 flex justify-end">
                <button
                  type="button"
                  className="py-1 px-2 bg-gray-300 text-gray-600 rounded"
                  onClick={() => handleRemoveItem(item.id)}
                  disabled={timeline.length === 1}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19.5 6l-1.112 13.226A2.25 2.25 0 0116.148 21H7.852a2.25 2.25 0 01-2.24-1.774L4.5 6m0 0H1.875M4.5 6h15M19.5 6H22.125M7.875 6V4.875A1.875 1.875 0 019.75 3h4.5A1.875 1.875 0 0116.125 4.875V6M9.75 11.25v5.25m4.5-5.25v5.25"
                    />
                  </svg>
                </button>
              </div> */}
            </div>
          </div>
        ))}
        {/* <button
          type="button"
          className="p-2 bg-blue-500 text-white rounded mb-4"
          onClick={handleAddItem}
        >
          + Add Item
        </button> */}
        <div className="col-span-2 text-right gap-4">
          <button
            type="button"
            className="p-2 bg-gray-500 text-white rounded"
            onClick={() => handleSubmit("draft")}
          >
            Save as Draft
          </button>
          <button className="ml-4 p-2 bg-blue-500 text-white rounded" type="submit">
            Save & finish
          </button>
        </div>
      </form>
    </div>
  );
};

export default TimeLine;