import React, { useState, useEffect } from "react";
import CompanyDetails from "./CompanyDetails";
import ItemDetails from "./ItemDetails";
import VariantDetails from "./VariantDetails";
import SlotInformation from "./SlotInformation";
import TimeLine from "./TimeLine";
import axios from "axios";

const FormTabs = ({ initialData }) => {
  const [activeTab, setActiveTab] = useState("CompanyDetails");
  const [formData, setFormData] = useState({
    companyDetails: initialData?.companyDetails || {},
    itemDetails: initialData?.itemDetails || {},
    variantDetails: initialData?.variantDetails || {},
    slotInformation: initialData?.slotInformation || {},
    timeLine: initialData?.timeLine || {},
  });

  useEffect(() => {
    if (initialData) {
      setFormData({
        companyDetails: initialData.companyDetails || {},
        itemDetails: initialData.itemDetails || {},
        variantDetails: initialData.variantDetails || {},
        slotInformation: initialData.slotInformation || {},
        timeLine: initialData.timeLine || {},
      });
    }
  }, [initialData]);

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  const handleFormDataChange = (section, data) => {
    setFormData({
      ...formData,
      [section]: data,
    });
  };

  return (
    <div className="container text-xs mx-auto p-4">
      <h1 className="text-blue-600 py-8 text-3xl flex text-semibold items-start">
        Add Product
      </h1>
      <div className="tabs flex items-center justify-center gap-4 mb-4">
        {[
          "CompanyDetails",
          "ItemDetails",
          "VariantDetails",
          "SlotInformation",
          "TimeLine",
        ].map((tab) => (
          <button
            key={tab}
            onClick={() => handleTabClick(tab)}
            className={`py-1 text-sm px-4 rounded ${
              activeTab === tab
                ? "bg-blue-500 text-white"
                : "bg-gray-200 text-black"
            }`}
          >
            {tab.replace(/([A-Z])/g, " $1").trim()}
          </button>
        ))}
      </div>
      <div className="tab-content p-4 bg-white">
        {activeTab === "CompanyDetails" && (
          <CompanyDetails
            onDataChange={(data) =>
              handleFormDataChange("companyDetails", data)
            }
            goToNextTab={() => handleTabClick("ItemDetails")}
          />
        )}
        {activeTab === "ItemDetails" && (
          <ItemDetails
            onDataChange={(data) => handleFormDataChange("itemDetails", data)}
            goToNextTab={() => handleTabClick("VariantDetails")}
          />
        )}
        {activeTab === "VariantDetails" && (
          <VariantDetails
            onDataChange={(data) =>
              handleFormDataChange("variantDetails", data)
            }
            goToNextTab={() => handleTabClick("SlotInformation")}
          />
        )}
        {activeTab === "SlotInformation" && (
          <SlotInformation
            onDataChange={(data) =>
              handleFormDataChange("slotInformation", data)
            }
            goToNextTab={() => handleTabClick("TimeLine")}
          />
        )}
        {activeTab === "TimeLine" && (
          <TimeLine
            onDataChange={(data) => handleFormDataChange("timeLine", data)}
          />
        )}
      </div>
    </div>
  );
};

export default FormTabs;