import React, { useState } from 'react'
import Zoom from "react-medium-image-zoom"
const TableRow = ({ data, i, setId }) => {
    const { product_images, item_name, brand_name, category_name, product_sku, session_id, } = data;

    return (
        <tr className='border-b'>
            <td className="px-2 py-1.5">
                {i + 1}
            </td>
            <td className="px-2 py-1.5">
                <Zoom><img className='w-12 ' src={product_images} alt={item_name} /></Zoom>
            </td>
            <td className="px-2 py-1.5">
                {item_name}
            </td>
            <td className="px-2 py-1.5">
                {product_sku}
            </td>
            <td className="px-2 py-1.5">
                {brand_name}
            </td>
            <td className="px-2 py-1.5">
                {category_name}
            </td>
            <td className="px-2 py-1.5">
                <button onClick={() => setId(session_id)} className='py-2 px-4 rounded-lg border'>Add Product Content</button>
            </td>
        </tr>
    )
}

export default TableRow