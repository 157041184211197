'use client'
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import UploadImage from './UploadImage';
import SelectBrand from './SelectBrand';

const BrandImageAndIdInput = ({ brandList, name }) => {
    const [data, setData] = useState([]);
    const [sideImage, setSideImage] = useState('');

    useEffect(() => {
        fetch(`${process.env.REACT_APP_URL}/v1/cms/get-home-page-banner?title=${name}&useCache=false`)
            .then(response => response.json())
            .then(data => {
                if (data?.response?.slider_images?.length > 0) {
                    setSideImage(data?.response?.image);
                    setData(data?.response?.slider_images);
                }
            })
            .catch(error => console.error('Error:', error));
    }, []);

    const [isLoading, setIsLoading] = useState(false);
    const maxItems = 100;

    const addInputField = () => {
        if (data?.length < maxItems) {
            setData([...data, { brand_id: '', img_url: '' }]);
        }
        if (data?.length === 0) {
            setData([{ brand_id: '', img_url: '' }]);
        }
    };

    const removeInputField = (indexToRemove) => {
        setData(data.filter((_, index) => index !== indexToRemove));
    };

    const updateItemValue = (index, value, name) => {
        setData(prevData =>
            prevData.map((item, i) =>
                i === index ? { ...item, [name]: value } : item
            )
        );
    };


    const handleCreateBrandBanner = async () => {
        setIsLoading(true);
        const body = {
            title: name,
            slider_images: data,
            image: sideImage
        };

        try {
            const response = await fetch(`${process.env.REACT_APP_URL}/v1/cms/set-home-page-banner`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('access_token')}`
                },
                body: JSON.stringify(body),
            });
            const data = await response.json();
            if (response.ok) {
                toast.success('Brand Banner added Successfully');
            } else {
                toast.error('Something went wrong');
            }
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setIsLoading(false);
        }
    };

    // Swapping functionality
    const moveUp = (index) => {
        if (index > 0) {
            const updatedData = [...data];
            [updatedData[index], updatedData[index - 1]] = [updatedData[index - 1], updatedData[index]];
            setData(updatedData);
        }
    };

    const moveDown = (index) => {
        if (index < data.length - 1) {
            const updatedData = [...data];
            [updatedData[index], updatedData[index + 1]] = [updatedData[index + 1], updatedData[index]];
            setData(updatedData);
        }
    };

    return (
        <>
            <div className='w-full flex flex-row'>
                <div className='w-80 flex flex-col'>
                    <h1>Side Image</h1>
                    <UploadImage
                        id={`side-image-${name}`}
                        name={`side-image`}
                        height='h-60'
                        onChange={(index, value) => setSideImage(value)}
                        value={sideImage}
                    />
                </div>
                <div className='flex-1 flex flex-row overflow-x-auto gap-5 items-center px-2 py-5'>
                    {data?.map((item, index) => (
                        <div key={name + index} className='w-[30%] shrink-0 relative'>
                            <div className='shrink-0 w-full h-60 border bg-gray-100'>
                                <UploadImage
                                    id={`image-${index}-name-${name}`}
                                    name={`image-${index}`}
                                    height='h-60'
                                    index={index}
                                    onChange={(index, value) => updateItemValue(index, value, 'img_url')}
                                    value={item.img_url}
                                />
                            </div>
                            <SelectBrand
                                id={`brand-${index}`}
                                name={`brand-${index}`}
                                value={item.brand_id}
                                index={index}
                                brandList={brandList}
                                onChange={(index, value) => updateItemValue(index, value, 'brand_id')}
                            />
                            {/* Swap buttons */}
                            <div className="absolute -top-4 right-10 flex gap-2">
                                <button
                                    type="button"
                                    onClick={() => moveUp(index)}
                                    disabled={index === 0}
                                    className={`p-1 rounded border border-gray-300 ${index === 0 ? 'opacity-50 cursor-not-allowed' : ''}`}
                                >
                                    ⬅️
                                </button>
                                <button
                                    type="button"
                                    onClick={() => moveDown(index)}
                                    disabled={index === data.length - 1}
                                    className={`p-1 rounded border border-gray-300 ${index === data.length - 1 ? 'opacity-50 cursor-not-allowed' : ''}`}
                                >
                                    ➡️
                                </button>
                            </div>
                            <button
                                type="button"
                                onClick={() => removeInputField(index)}
                                className="absolute -top-4 -right-4 z-10 p-1 rounded-sm border border-gray-300"
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 text-gray-700">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                        </div>
                    ))}
                    <div className='shrink-0 w-[30%] h-80 flex justify-center items-center '>
                        <button
                            disabled={data?.length === maxItems}
                            type="button"
                            onClick={addInputField}
                            className='text-5xl text-gray-700 font-medium py-1 pb-3 px-4 border border-gray-300 rounded'
                        >
                            +
                        </button>
                    </div>
                </div>
            </div>
            {data.length > 0 && (
                <div className='flex justify-center items-center'>
                    <button
                        disabled={isLoading}
                        onClick={handleCreateBrandBanner}
                        type="button"
                        className={`w-fit bg-teal-500 text-white px-24 py-2 rounded ${isLoading && 'animate-pulse'}`}
                    >
                        Save
                    </button>
                </div>
            )}
        </>
    );
};

export default BrandImageAndIdInput;
