import React, { useEffect, useState } from "react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const CountryListModal = ({ visible, onClose, id, modalName }) => {
  const [fileInputState, setFileInputState] = useState("");
  const [logo, setLogo] = useState();
  const [banner, setBanner] = useState();
  const [appBanner, setAppBanner] = useState();
  const [webBanner, setWebBanner] = useState(); // New state for Web Banner

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      if (e.target.name === "logoInput") {
        setLogo(reader.result);
      } else if (e.target.name === "bannerInput") {
        setBanner(reader.result);
      } else if (e.target.name === "appBannerInput") {
        setAppBanner(reader.result);
      } else if (e.target.name === "webBannerInput") { // New web banner case
        setWebBanner(reader.result); // Update webBanner state
      }
    };
  };

  const updateData = (url, requestBody) => {
    fetch(url, requestBody)
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          onClose();
          console.log(data);
        }
      })
      .catch((error) => console.log(error));
  };

  const [formData, setFormData] = useState({
    country_name: "",
    image: "",
    banner: "",
    app_banner: "",
    web_banner: "", // Add web_banner to formData
    heading: "", // Add heading to formData
    sub_heading: "", // Add sub_heading to formData
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((preValue) => ({ ...preValue, [name]: value }));
  };

  const handleContentChange = (value) => {
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const token = localStorage.getItem("access_token");
    const data = {
      country_name: formData.country_name,
      image: logo,
      banner: banner,
      app_banner: appBanner,
      web_banner: webBanner, // Include web_banner in the request
      heading: formData.heading, // Include heading in the request
      sub_heading: formData.sub_heading, // Include sub_heading in the request
    };
    
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    };

    const url =
      modalName === "edit"
        ? `${process.env.REACT_APP_URL}/v1/country/update/${id}`
        : `${process.env.REACT_APP_URL}/v1/country/add`;

    updateData(url, requestOptions);

    setFormData({
      country_name: "",
      image: "",
      banner: "",
      app_banner: "",
      web_banner: "",
      heading: "",
      sub_heading: "",
    });
    setLogo(null);
    setBanner(null);
    setAppBanner(null);
    setWebBanner(null);
  };

  const [view, setView] = useState([]);
  useEffect(() => {
    if (modalName === "view" || modalName === "edit") {
      fetch(`${process.env.REACT_APP_URL}/v1/country/single-country/${id}`)
        .then((res) => res.json())
        .then((data) => {
          setView(data.result);
          setFormData({
            country_name: data.result.country_name,
            image: data.result.image,
            banner: data.result.banner,
            app_banner: data.result.app_banner,
            web_banner: data.result.web_banner,
            heading: data.result.heading,
            sub_heading: data.result.sub_heading,
          });
          setLogo(data.result.image);
          setBanner(data.result.banner);
          setAppBanner(data.result.app_banner);
          setWebBanner(data.result.web_banner);
        });
    }
  }, [id, modalName]);

  if (!visible) return null;

  return (
    <div className="fixed z-10 inset-0 bg-black bg-opacity-30 backdrop-blur-sm flex justify-center items-center">
      <div className="mx-auto bg-white rounded py-5 px-10">
        <form onSubmit={handleSubmit} className="flex flex-col">
          <div className="flex gap-5">
            <div className="w-1/2">
              <p className="w-fit text-sm text-gray-900 py-1 uppercase">
                Country Name
              </p>
              <textarea
                onChange={handleChange}
                name="country_name"
                value={formData.country_name}
                placeholder={view && view.country_name}
                disabled={modalName === "view"}
                className="h-10 w-full my-2 px-4 outline-0 border border-solid border-gray-200 resize-none rounded-md"
                type="text"
              />
            </div>

            {/* Logo Upload and Preview */}
            <div>
              <p className="w-fit text-sm text-gray-900 py-1 uppercase">Logo</p>
              <div>
                <input
                  onChange={handleFileInputChange}
                  disabled={modalName === "view"}
                  type="file"
                  name="logoInput"
                  className="border mt-6 p-1"
                />
              </div>
              {logo && (
                <div className="flex gap-x-2 items-center">
                  <p>Preview:</p>
                  <img
                    src={logo}
                    alt="Logo Preview"
                    className="mt-4 w-24 h-24 object-cover"
                  />
                </div>
              )}
            </div>

            {/* Banner Upload and Preview */}
            <div>
              <p className="w-fit text-sm text-gray-900 py-1 uppercase">
                Banner
              </p>
              <div>
                <input
                  onChange={handleFileInputChange}
                  disabled={modalName === "view"}
                  type="file"
                  name="bannerInput"
                  className="border mt-6 p-1"
                />
              </div>
              {banner && (
                <img
                  src={banner}
                  alt="Banner Preview"
                  className="mt-4 w-48 h-24 object-cover"
                />
              )}
            </div>

            {/* App Banner Upload and Preview */}
            <div>
              <p className="w-fit text-sm text-gray-900 py-1 uppercase">
                App Banner
              </p>
              <div>
                <input
                  onChange={handleFileInputChange}
                  disabled={modalName === "view"}
                  type="file"
                  name="appBannerInput"
                  className="border mt-6 p-1"
                />
              </div>
              {appBanner && (
                <img
                  src={appBanner}
                  alt="App Banner Preview"
                  className="mt-4 w-48 h-24 object-cover"
                />
              )}
            </div>

            {/* Web Banner Upload and Preview */}
            <div>
              <p className="w-fit text-sm text-gray-900 py-1 uppercase">
                Web Banner
              </p>
              <div>
                <input
                  onChange={handleFileInputChange}
                  disabled={modalName === "view"}
                  type="file"
                  name="webBannerInput"
                  className="border mt-6 p-1"
                />
              </div>
              {webBanner && (
                <img
                  src={webBanner}
                  alt="Web Banner Preview"
                  className="mt-4 w-48 h-24 object-cover"
                />
              )}
            </div>
          </div>

          {/* Heading Input */}
          <div className="w-full">
            <p className="w-fit text-sm text-gray-900 py-1 uppercase">Heading</p>
            <textarea
              onChange={handleChange}
              name="heading"
              value={formData.heading}
              placeholder={view && view.heading}
              disabled={modalName === "view"}
              className="h-10 w-full my-2 px-4 outline-0 border border-solid border-gray-200 resize-none rounded-md"
              type="text"
            />
          </div>

          {/* Sub Heading Input */}
          <div className="w-full">
            <p className="w-fit text-sm text-gray-900 py-1 uppercase">Sub Heading</p>
            <textarea
              onChange={handleChange}
              name="sub_heading"
              value={formData.sub_heading}
              placeholder={view && view.sub_heading}
              disabled={modalName === "view"}
              className="h-10 w-full my-2 px-4 outline-0 border border-solid border-gray-200 resize-none rounded-md"
              type="text"
            />
          </div>

          <div className="flex gap-x-5 justify-center my-2">
            <div
              onClick={onClose}
              className="py-2 px-4 bg-white text-red-600 rounded-sm cursor-pointer"
            >
              Cancel
            </div>
            {modalName !== "view" && (
              <button
                className="py-2 px-10 bg-[#00388c] text-white rounded-lg uppercase"
                type="submit"
              >
                {modalName === "edit" ? "Update" : "Add New"}
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default CountryListModal;
