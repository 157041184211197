import React from 'react';
import { useNavigate } from 'react-router-dom';
const TableRow = ({ data, sessionId }) => {
    const { content_name, updatedAt, status, _id } = data
    const navigate = useNavigate();
    const handleEdit = () => {
        const redirectUrl = `/preorder/cms/add-edit/${sessionId}?content_id=${_id}&isEditable=true`
        navigate(redirectUrl)
    }
    const handlePreview = () => {
        const redirectUrl = `/preorder/cms/preview/${sessionId}`
        navigate(redirectUrl)
    }
    return (
        <tr className="border-b border-solid border-gray-200 bg-white text-[#222222]">
            <td scope="row" className="whitespace-nowrap px-6 py-2 font-medium text-gray-900">{content_name}</td>

            <td scope="row" className="whitespace-nowrap px-6 py-2 font-medium text-gray-900">
                {updatedAt?.slice(0, 10)}
            </td>
            <td scope="row" className="whitespace-nowrap px-6 py-2 font-medium text-gray-900">
                <button type='button' className='py-2 px-4 rounded'>{status}</button>
            </td>
            <td scope="row" className="whitespace-nowrap px-6 py-2 font-medium text-gray-900">
                <div className='flex space-x-2'>
                    <button
                        onClick={() => handleEdit()}
                        type='button' className='py-2 px-4 rounded text-white bg-teal-500'>
                        Edit
                    </button>
                    <button
                        onClick={() => handlePreview()}
                        type='button' className='py-2 px-4 rounded text-white bg-teal-500'>
                        Preview
                    </button>
                </div>
            </td>
        </tr>
    );
}

export default TableRow;