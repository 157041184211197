import React from 'react'
import RenderHTML from './RenderHTML'

const ProductDescription = ({ data }) => {
    const { body_text, title } = data;
    return (
        <div id={title.replace(/ /g, '_')}>
            <RenderHTML
                htmlContent={body_text}
            />
        </div>
    )
}

export default ProductDescription