import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";

const AwardListModal = ({ visible, onClose, id, modalName }) => {
  const [fileInputState, setFileInputState] = useState("");
  const [selectedFile, setSelectedFile] = useState();
  const [bannerInputState, setBannerInputState] = useState("");
  const [selectedBanner, setSelectedBanner] = useState();
  const [webBannerInputState, setWebBannerInputState] = useState("");
  const [selectedWebBanner, setSelectedWebBanner] = useState();
  const [awardSpecifications, setAwardSpecifications] = useState([
    { heading: "", description: "", points: "" },
  ]);

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setSelectedFile(reader.result);
      setFileInputState(e.target.value);
    };
  };

  const handleBannerInputChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setSelectedBanner(reader.result);
      setBannerInputState(e.target.value);
    };
  };

  const handleWebBannerInputChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setSelectedWebBanner(reader.result);
      setWebBannerInputState(e.target.value);
    };
  };

  const updateData = (url, requestBody) => {
    fetch(url, requestBody)
      .then((response) => response.json())
      .then((data) => {
        toast.success(data.message);
        onClose();
      })
      .catch((error) => {
        console.error("Error updating data:", error);
        toast.error(error.response.data.message);
      });
  };

  const [formData, setFormData] = useState({
    award_name: "",
    award_description: "",
    country: "",
    image: "",
    app_banner: "",
    web_banner: "",
    award_category: "",
    exhibition: "",
    judging_method_description: "",
    judging_method_points: "",
    award_level_description: "",
    award_level_points: "",
    notable_patrons_description: "",
    notable_patrons_points: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((preValue) => ({
      ...preValue,
      [name]: value,
    }));
  };

  const handleAwardSpecChange = (index, e) => {
    const { name, value } = e.target;
    const updatedSpecs = [...awardSpecifications];
    updatedSpecs[index][name] = value;
    setAwardSpecifications(updatedSpecs);
  };

  const addAwardSpec = () => {
    setAwardSpecifications([
      ...awardSpecifications,
      { heading: "", description: "", points: "" },
    ]);
  };

  const removeAwardSpec = (index) => {
    const updatedSpecs = awardSpecifications.filter((_, i) => i !== index);
    setAwardSpecifications(updatedSpecs);
  };

  const token = localStorage.getItem("access_token");

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      award_name: formData.award_name,
      award_description: formData.award_description,
      country: formData.country,
      image: selectedFile,
      app_banner: selectedBanner,
      web_banner: selectedWebBanner,
      award_specification: awardSpecifications.map((spec) => ({
        heading: spec.heading,
        description: spec.description,
        points: spec.points ? spec.points.split(",") : [],
      })),
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    };

    const url =
      modalName === "edit"
        ? `${process.env.REACT_APP_URL}/v1/award/update/${id}`
        : `${process.env.REACT_APP_URL}/v1/award/add`;

    updateData(url, requestOptions);

    setFormData({
      award_name: "",
      award_description: "",
      country: "",
      image: "",
      app_banner: "",
      web_banner: "",
      award_category: "",
      exhibition: "",
      judging_method_description: "",
      judging_method_points: "",
      award_level_description: "",
      award_level_points: "",
      notable_patrons_description: "",
      notable_patrons_points: "",
    });
    setSelectedFile(null);
    setSelectedBanner(null);
    setSelectedWebBanner(null);
    setAwardSpecifications([{ heading: "", description: "", points: "" }]);
  };

  const [view, setView] = useState({});

  useEffect(() => {
    if (modalName === "view" || modalName === "edit") {
      fetch(`${process.env.REACT_APP_URL}/v1/award/single-award/${id}`)
        .then((res) => res.json())
        .then((data) => {
          setView(data.result);
          const awardSpecs = data.result.award_specification || [];
          setFormData({
            award_name: data.result.award_name,
            award_description: data.result.award_description,
            country: data.result.country,
            image: data.result.image,
            app_banner: data.result.app_banner,
            web_banner: data.result.web_banner,
          });
          setAwardSpecifications(
            awardSpecs.map((spec) => ({
              heading: spec.heading,
              description: spec.description,
              points: spec.points.join(","),
            }))
          );
          setSelectedFile(data.result.image); // Set initial image preview
          setSelectedBanner(data.result.app_banner); // Set initial banner preview
          setSelectedWebBanner(data.result.web_banner); // Set initial web banner preview
        });
    }
  }, [modalName, id]);

  if (visible) {
    return (
      <div className="fixed z-10 inset-0 bg-black bg-opacity-30 backdrop-blur-sm flex justify-center items-center">
        <div className="w-2/3 h-3/4 mx-auto bg-white rounded py-5 px-10 no-scrollbar overflow-y-scroll">
          <form onSubmit={handleSubmit} className="flex flex-col ">
            <div className="flex gap-5">
              <div className="w-1/2 flex flex-col">
                <p className="w-fit text-xs text-gray-900 py-1 uppercase">
                  Award name
                </p>
                <input
                  autoComplete="off"
                  onChange={handleChange}
                  name="award_name"
                  disabled={modalName === "view"}
                  value={formData.award_name}
                  placeholder={view?.award_name || ""}
                  className="h-10 my-2 px-4 outline-0 border border-solid border-gray-200 rounded-md"
                  type="text"
                />
              </div>
              <div className="w-1/2 h-f flex flex-col">
                <p className="w-fit text-xs text-gray-900 py-1 uppercase">
                  Logo
                </p>
                <input
                  onChange={handleFileInputChange}
                  disabled={modalName === "view"}
                  type="file"
                  className="border mt-3 p-1"
                />
                {(selectedFile || view?.image) && (
                  <img
                    src={selectedFile || view.image}
                    alt="Award Logo Preview"
                    className="mt-2 h-20 w-20 object-cover rounded"
                  />
                )}
              </div>
            </div>

            <p className="w-fit text-xs text-gray-900 py-1.5 uppercase">
              App Banner
            </p>
            <input
              onChange={handleBannerInputChange}
              disabled={modalName === "view"}
              type="file"
              className="border mt-2 p-1"
            />
            {(selectedBanner || view?.app_banner) && (
              <img
                src={selectedBanner || view.app_banner}
                alt="App Banner Preview"
                className="mt-2 h-20 w-full object-cover rounded"
              />
            )}

            <p className="w-fit text-xs text-gray-900 py-1.5 uppercase">
              Web Banner
            </p>
            <input
              onChange={handleWebBannerInputChange}
              disabled={modalName === "view"}
              type="file"
              className="border mt-2 p-1"
            />
            {(selectedWebBanner || view?.web_banner) && (
              <img
                src={selectedWebBanner || view.web_banner}
                alt="Web Banner Preview"
                className="mt-2 h-20 w-full object-cover rounded"
              />
            )}

            <p className="w-fit text-xs text-gray-900 py-1.5 uppercase">
              Country Name
            </p>
            <textarea
              onChange={handleChange}
              name="country"
              value={formData.country}
              placeholder={view?.country || ""}
              disabled={modalName === "view"}
              className="h-10 w-1/2 my-2 px-4 outline-0 border border-solid border-gray-200 resize-none rounded-md"
              type="text"
            />

            <p className="w-fit text-xs text-gray-900 py-1.5 uppercase">
              Description
            </p>
            <textarea
              maxLength={160}
              onChange={handleChange}
              name="award_description"
              value={formData.award_description}
              placeholder={view?.award_description || ""}
              disabled={modalName === "view"}
              className="h-24 my-2 px-4 outline-0 border border-solid border-gray-200 resize-none rounded-md"
              type="text"
            />

            {awardSpecifications.length === 0 && modalName !== "view" && (
              <>
                <p className="w-fit text-xs text-gray-900 py-1.5 uppercase">
                  Add specification
                </p>
                <button
                  type="button"
                  onClick={addAwardSpec}
                  className="p-1 w-1/6 justify-center bg-blue-300 text-white rounded-md my-2 flex items-center"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                    />
                  </svg>
                </button>
              </>
            )}

            {awardSpecifications.map((spec, index) => (
              <div key={index} className="flex flex-col mb-4">
                <div className="flex gap-5 items-end">
                  <div className="flex flex-col w-1/4">
                    <p className="w-fit text-xs text-gray-900 py-1.5 uppercase">
                      Heading
                    </p>
                    <input
                      autoComplete="off"
                      onChange={(e) => handleAwardSpecChange(index, e)}
                      name="heading"
                      disabled={modalName === "view"}
                      value={spec.heading}
                      className="h-10 my-2 px-4 outline-0 border border-solid border-gray-200 rounded-md"
                      type="text"
                    />
                  </div>
                  <div className="flex flex-col w-1/4">
                    <p className="w-fit text-xs text-gray-900 py-1.5 uppercase">
                      Description
                    </p>
                    <input
                      autoComplete="off"
                      onChange={(e) => handleAwardSpecChange(index, e)}
                      name="description"
                      disabled={modalName === "view"}
                      value={spec.description}
                      className="h-10 my-2 px-4 outline-0 border border-solid border-gray-200 rounded-md"
                      type="text"
                    />
                  </div>
                  <div className="flex flex-col w-1/4">
                    <p className="w-fit text-xs text-gray-900 py-1.5 uppercase">
                      Points (comma separated)
                    </p>
                    <input
                      autoComplete="off"
                      onChange={(e) => handleAwardSpecChange(index, e)}
                      name="points"
                      disabled={modalName === "view"}
                      value={spec.points}
                      className="h-10 my-2 px-4 outline-0 border border-solid border-gray-200 rounded-md"
                      type="text"
                    />
                  </div>
                  {modalName !== "view" && (
                    <>
                      <button
                        type="button"
                        onClick={() => removeAwardSpec(index)}
                        className="p-1 bg-red-300 text-white rounded-md my-2 flex items-center"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="24"
                          height="24"
                          color="#000000"
                          fill="none"
                        >
                          <path
                            d="M16 12L8 12"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12Z"
                            stroke="currentColor"
                            strokeWidth="1.5"
                          />
                        </svg>
                      </button>
                      {index === awardSpecifications.length - 1 && (
                        <button
                          type="button"
                          onClick={addAwardSpec}
                          className="p-1 bg-blue-300 text-white rounded-md my-2 flex items-center"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                            />
                          </svg>
                        </button>
                      )}
                    </>
                  )}
                </div>
              </div>
            ))}

            <div className="flex gap-x-5 justify-center my-2">
              <div
                onClick={onClose}
                className="py-2 px-4 bg-white text-red-600 rounded-sm cursor-pointer"
              >
                Cancel
              </div>
              {modalName !== "view" && (
                <button
                  className="py-2 px-10 bg-[#00388c] text-white rounded-lg uppercase"
                  type="submit"
                >
                  {modalName === "edit" ? "Update" : "Add New"}
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    );
  }

  return null;
};

export default AwardListModal;
