'use client'
import React, { useState } from 'react'
import UpArrow from './UpArrow'
import DownArrow from './DownArrow'
import Remove from './Remove'
import Editor from './Editor'
const ProductDescription = ({ index, initialValues, onDataChange }) => {
    const [formData, setFormData] = useState({
        body_text: initialValues?.body_text || '',
        title: initialValues?.title || ''
    });

    const handleFieldChange = (field, value) => {
        const updatedFormData = { ...formData, [field]: value };
        setFormData(updatedFormData);
        onDataChange(updatedFormData);
    };

    return (
        <div
            id={`standard_product_description_${index}}`}
            className='w-full flex flex-col border'>
            <div className='flex justify-between items-center border pl-4'>
                <div>
                    <h1 className='text-xl text-black font-semibold '>Standard Product Description </h1>
                </div>
                <div className='flex' >
                    <UpArrow index={index} />
                    <DownArrow index={index} />
                    <Remove index={index} />
                </div>
            </div>

            <div className='my-2 px-5'>
                <label htmlFor="left-image-with-text-headline" className="text-left block mb-2 font-medium  text-gray-700 ">Title</label>
                <input
                    type="text"
                    id="left-image-with-text-headline"
                    className="block w-full text-gray-900 border border-gray-300 rounded-sm bg-gray-50 outline-none py-1 px-2 text-sm"
                    placeholder="Enter headline text"
                    maxLength={100}
                    required
                    value={formData?.title}
                    onChange={(e) => handleFieldChange('title', e.target.value)}
                />
            </div>
            <div className='mt-5 p-2'>
                <label htmlFor="body-text" className="block mb-2 text-sm  text-gray-700 ">Body text</label>
                <Editor
                    value={formData?.body_text}
                    isRequired={true}
                    maxLength={500}
                    name={`body_text`}
                    onChange={handleFieldChange}
                />
            </div>

        </div>
    )
}

export default ProductDescription;