import React, { useState } from 'react';
import toast from 'react-hot-toast';
import UploadIcon from './UploadIcon';

const UploadVideo = ({ id, name, height, onChange, value, aspectRatio, requiredRatio, tolerance }) => {
    const [videoPreview, setVideoPreview] = useState(value?.video_url);

    const handleVideoChange = async (e) => {
        const file = e.target.files[0];

        if (file) {
            const fileSizeInBytes = file.size;
            const maxSizeInBytes = 100 * 1024 * 1024; // 100MB in bytes

            if (fileSizeInBytes > maxSizeInBytes) {
                // File exceeds the size limit
                toast.error("File size exceeds the limit (50MB). Please choose a smaller file.");
                return;
            }

            const reader = new FileReader();
            setVideoPreview(URL.createObjectURL(file));
            reader.onload = (event) => {
                const dataURL = event.target.result;
                onChange(name, dataURL); // Pass the data URL to onChange
            };

            reader.readAsDataURL(file); // Read the file as a data URL
        }
    };



    return (
        <div className="flex items-center justify-center w-full ">
            <label htmlFor={id} className={`${height} flex flex-col items-center justify-center w-full  border-2 border-teal-500 border-dashed rounded-lg cursor-pointer bg-gray-50`}>
                {videoPreview ? (
                    <video src={videoPreview} autoPlay loop muted className={`w-full h-full`}></video>
                ) : (
                    <>
                        <div className="flex flex-col items-center justify-center mt-5 pt-1 px-4 border border-teal-500">
                            <UploadIcon />
                            <p className='p-1 text-teal-500'>{requiredRatio}</p>
                        </div>
                        <p className="mb-2 text-sm text-teal-500">Click to add video</p>
                    </>
                )}
                <input
                    id={id}
                    type="file"
                    className="opacity-0"
                    accept="video/*"
                    onChange={(e) => handleVideoChange(e)}
                />
            </label>
        </div>
    );
};

export default UploadVideo;
