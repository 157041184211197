'use client'
import React, { useState } from 'react'
import UpArrow from './UpArrow'
import DownArrow from './DownArrow'
import Remove from './Remove'
import UploadVideo from './UploadVideo'

const BigVideo = ({ index, initialValues, onDataChange }) => {
    const [formData, setFormData] = useState({
        video: initialValues?.video || '',
        video_public_id: initialValues?.video_public_id,
        title: initialValues?.title || ''
    });

    const handleFieldChange = (field, value) => {
        let updatedFormData;
        updatedFormData = { ...formData, [field]: value };
        setFormData(updatedFormData);
        onDataChange(updatedFormData);
    };

    return (
        <div
            id={`BigBrandImageBanner_${index}}`}
            className='w-full flex flex-col border'>
            <div className='flex justify-between items-center border pl-4'>
                <div>
                    <h1 className='text-xl text-black font-semibold '>Big Video </h1>
                </div>
                <div className='flex' >
                    <UpArrow index={index} />
                    <DownArrow index={index} />
                    <Remove index={index} />
                </div>
            </div>
            <div className='my-2 px-5'>
                <label htmlFor="left-image-with-text-headline" className="text-left block mb-2 font-medium  text-gray-700 ">Title</label>
                <input
                    type="text"
                    id="left-image-with-text-headline"
                    className="block w-full text-gray-900 border border-gray-300 rounded-sm bg-gray-50 outline-none py-1 px-2 text-sm"
                    placeholder="Enter headline text"
                    maxLength={100}
                    required
                    value={formData?.title}
                    onChange={(e) => handleFieldChange('title', e.target.value)}
                />
            </div>
            <div className='mt-5 p-2'>
                <UploadVideo
                    id={`big-video-${index}`}
                    height={`h-[40rem]`}
                    name={`video`}
                    aspectRatio={970 / 600}
                    requiredRatio={`970 / 600`}
                    tolerance={0.2}
                    value={formData?.video}
                    onChange={handleFieldChange}
                />

            </div>


        </div>
    )
}

export default BigVideo