import ImageHeaderWithText from './ImageHeaderWithText';
import ProductDescription from './ProductDescription';
import CompanyLogo from './CompanyLogo';
import GridTwoImage from './GridTwoImage';
import BigHeadingAndDescription from './BigHeadingAndDescription';
import BigBanner from './BigBanner';
import BigVideo from './BigVideo';
// Add other components as needed

const componentRegistry = {
    ImageHeaderWithText,
    ProductDescription,
    CompanyLogo,
    GridTwoImage,
    BigHeadingAndDescription,
    BigBanner,
    BigVideo
    // Add other components as needed
};

export default componentRegistry;
