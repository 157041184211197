import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

const VariantDetails = ({ goToNextTab }) => {
  const {id} = useParams();
  const [formData, setFormData] = useState({
    has_variation: false,
    variations: [],
  });

  const [selectedVariations, setSelectedVariations] = useState({
    style: false,
    color: false,
    size: false,
    model: false,
  });

  const [variationValues, setVariationValues] = useState({
    style: "",
    color: "",
    size: "",
    model: "",
  });

  const [variantSelectionDisabled, setVariantSelectionDisabled] =
    useState(false);
  const [hasVariationDisabled, setHasVariationDisabled] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const accessToken = localStorage.getItem("access_token");

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_URL}/v1/pre-order/get-variant-details?preorder_session_id=${id}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        const { data } = response;
        if (data.response && data.response.length > 0) {
          const fetchedVariations = data.response.map((item) => {
            const variation = {
              sku: item.product_sku,
              qty_available: item.qty_available,
              main_img: "",
            };

            item.select_varient.forEach((variant, index) => {
              variation[variant] = item[`value${index === 0 ? "" : index + 1}`];
              setSelectedVariations((prev) => ({ ...prev, [variant]: true }));
              setVariationValues((prev) => ({
                ...prev,
                [variant]: prev[variant]
                  ? `${prev[variant]}, ${
                      item[`value${index === 0 ? "" : index + 1}`]
                    }`
                  : item[`value${index === 0 ? "" : index + 1}`],
              }));
            });

            return variation;
          });

          setFormData({
            has_variation: true,
            variations: fetchedVariations,
          });

          setVariantSelectionDisabled(true);
          setHasVariationDisabled(true);
        }
      } catch (error) {
        console.error("API Error:", error);
      }
    };

    fetchData();
  }, []);

  const handleChange = (e) => {
    const { name, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : e.target.value,
    });
  };

  const handleVariationSelection = (e) => {
    const { name, checked } = e.target;
    const selectedCount =
      Object.values(selectedVariations).filter(Boolean).length;

    if (checked && selectedCount >= 2) {
      alert("You can only select up to two variations.");
      return;
    }

    setSelectedVariations({ ...selectedVariations, [name]: checked });
  };

  const handleVariationValuesChange = (e) => {
    const { name, value } = e.target;
    setVariationValues({ ...variationValues, [name]: value });
  };

  const generateCombinations = () => {
    const selectedVariationNames = Object.keys(selectedVariations).filter(
      (variation) => selectedVariations[variation]
    );

    const variationArrays = selectedVariationNames.map((variation) =>
      variationValues[variation].split(",").map((value) => value.trim())
    );

    const combinations = generateCartesianProduct(variationArrays);

    const variations = combinations.map((combination) => {
      const variationObj = {
        sku: "",
        main_img: "",
        qty_available: "",
      };
      selectedVariationNames.forEach((variation, index) => {
        variationObj[variation] = combination[index];
      });
      return variationObj;
    });

    setFormData({ ...formData, variations });
  };

  const generateCartesianProduct = (arrays) => {
    return arrays.reduce(
      (acc, array) =>
        acc.flatMap((accItem) =>
          array.map((arrayItem) => [...accItem, arrayItem])
        ),
      [[]]
    );
  };

  const handleNestedArrayChange = (e, index, key) => {
    const newArray = [...formData.variations];
    newArray[index][key] = e.target.value;
    setFormData({ ...formData, variations: newArray });
  };

  const handleImageChange = (e, index) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const newVariations = [...formData.variations];
        newVariations[index].main_img = reader.result;
        setFormData({ ...formData, variations: newVariations });
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDeleteVariation = (index) => {
    const newVariations = formData.variations.filter((_, i) => i !== index);
    setFormData({ ...formData, variations: newVariations });
  };

  const handleSubmit = async ({ e, actionType, goToNextTab }) => {
    e.preventDefault();
  
    const preorderSessionId = localStorage.getItem("preorder_session_id");
    const accessToken = localStorage.getItem("access_token");
  
    if (!preorderSessionId) {
      alert("Preorder session ID is missing.");
      return;
    }
  
    // Check if there are variations
    if (formData.has_variation == "false") {
      alert("No variations to submit.");
      goToNextTab()
    }
  
    const selectedVariationNames = Object.keys(selectedVariations).filter(
      (variation) => selectedVariations[variation]
    );
  
    const variationData = formData.variations.map((variation) => {
      const variationObj = {
        name: selectedVariationNames[0],
        value: variation[selectedVariationNames[0]],
        main_img: variation.main_img,
        product_sku: variation.sku,
        qty_available: variation.qty_available,
      };
  
      if (selectedVariationNames.length > 1) {
        variationObj.name2 = selectedVariationNames[1];
        variationObj.value2 = variation[selectedVariationNames[1]];
      }
  
      return variationObj;
    });
  
    const apiBody = {
      preorder_session_id: preorderSessionId || id,
      has_variation: formData.has_variation,
      select_varient: selectedVariationNames,
      variation_data: variationData,
    };
  
    try {
      if(formData.has_variation){
        const response = await axios.post(
          `${process.env.REACT_APP_URL}/v1/pre-order/add-variant-details`,
          apiBody,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
      }

      // console.log("API Response:", response.data);
      if (actionType === "next" && typeof goToNextTab === "function") {
        goToNextTab();
      }
    } catch (error) {
      console.error("API Error:", error);
    }
  };
  

  const variationLabels = {
    style: "Style",
    color: "Color",
    size: "Size",
    model: "Model",
  };

  const selectedVariationNames = Object.keys(selectedVariations).filter(
    (variation) => selectedVariations[variation]
  );

  return (
    <div className=" max-w-7xl flex pr-4">
      <div className="max-w-full grow mx-4">
        <h2 className="text-xl text-left text-blue-600 font-semibold mb-4">
          Variant Details
        </h2>
        <form onSubmit={handleSubmit}>
          <div className="mt-6">
            <label className="block text-sm font-medium text-gray-600 mb-2">
              Does this product have variations?
            </label>
            <div>
              <input
                type="checkbox"
                name="has_variation"
                checked={formData.has_variation}
                onChange={handleChange}
                className="mr-2"
                disabled={hasVariationDisabled}
              />
              <label>Yes</label>
              <input
                type="checkbox"
                name="has_variation"
                checked={!formData.has_variation}
                onChange={handleChange}
                className="mr-2 ml-4"
                disabled={hasVariationDisabled}
              />
              <label>No</label>
            </div>
          </div>

          {formData.has_variation && (
            <>
              <div className="mt-6 flex gap-4">
                <label className="block text-sm font-medium text-gray-600 mb-2">
                  Select Variations:
                </label>
                <div className="flex gap-4">
                  {Object.keys(variationLabels).map((variation) => (
                    <div key={variation} className="flex items-center">
                      <input
                        type="checkbox"
                        name={variation}
                        checked={selectedVariations[variation]}
                        onChange={handleVariationSelection}
                        disabled={variantSelectionDisabled}
                        className="mr-2"
                      />
                      <label className="text-sm text-gray-600">
                        {variationLabels[variation]}
                      </label>
                    </div>
                  ))}
                </div>
              </div>

              <div className="mt-6 flex gap-4">
                {selectedVariationNames.map((variation) => (
                  <div key={variation}>
                    <label className="block text-sm font-medium text-gray-600 mb-1">
                      {variationLabels[variation]}:
                    </label>
                    <input
                      type="text"
                      name={variation}
                      value={variationValues[variation]}
                      onChange={handleVariationValuesChange}
                      className="w-full p-2 border border-gray-300 rounded-md"
                    />
                  </div>
                ))}
              </div>

              <button
                type="button"
                onClick={generateCombinations}
                className="mt-4 p-2 bg-green-100 text-green-600 rounded-md"
              >
                Generate Combinations
              </button>

              {formData.variations.length > 0 && (
                <div className="mt-6">
                  <h3 className="text-lg font-semibold text-gray-700 mb-4">
                    Variations
                  </h3>
                  <div className="w-full grid grid-cols-1 col-span-2 gap-4">
                    {formData.variations.map((variation, index) => (
                      <div key={index} className="flex gap-4">
                        {selectedVariationNames.map((name) => (
                          <div key={name} className="w-full">
                            <label className="block text-sm font-medium text-gray-600 mb-1">
                              {variationLabels[name]}:
                            </label>
                            <input
                              type="text"
                              value={variation[name]}
                              className="w-full p-2 border border-gray-300 rounded-md"
                              readOnly
                            />
                          </div>
                        ))}
                        <div className="w-full">
                          <label className="block text-sm font-medium text-gray-600 mb-1">
                            SKU:
                          </label>
                          <input
                            type="text"
                            name="sku"
                            value={variation.sku}
                            onChange={(e) =>
                              handleNestedArrayChange(e, index, "sku")
                            }
                            className="w-full p-2 border border-gray-300 rounded-md"
                          />
                        </div>
                        <div className="w-full">
                          <label className="block text-sm font-medium text-gray-600 mb-1">
                            Image:
                          </label>
                          <input
                            type="file"
                            onChange={(e) => handleImageChange(e, index)}
                            className="w-full p-2 border border-gray-300 rounded-md"
                          />
                        </div>
                        <div className="w-full">
                          <label className="block text-sm font-medium text-gray-600 mb-1">
                            Quantity:
                          </label>
                          <input
                            type="number"
                            name="qty_available"
                            value={variation.qty_available}
                            onChange={(e) =>
                              handleNestedArrayChange(e, index, "qty_available")
                            }
                            className="w-full p-2 border border-gray-300 rounded-md"
                          />
                        </div>
                        <button
                          type="button"
                          onClick={() => handleDeleteVariation(index)}
                          className="mt-6 p-2 bg-red-100 text-red-600 rounded-md"
                        >
                          Delete
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </>
          )}

          <div className="col-span-2 mt-2 text-right gap-4">
            <button
              type="button"
              className="px-4 py-2 bg-gray-500 text-white rounded text-sm"
            >
              Save as Draft
            </button>
            <button
              type="submit"
              className=" ml-4 px-4 py-2 bg-blue-500 text-white rounded text-sm"
              onClick={(e) =>
                handleSubmit({ e, actionType: "next", goToNextTab })
              }
            >
              Save & Next
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default VariantDetails;