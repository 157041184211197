import React, { useState, useEffect } from "react";
import AddSellerModal from "./AddSellerModal";
import AddBrandModal from "./AddBrandModal";
import SideBar from "../Sidebar";
import { categoryMenu } from "../../constant";
import { getToken } from "../../hook/getToken";
import SellerTableRow from "./SellerTableRow"; // Import SellerTableRow component

const AddSellerBrand = () => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [isSellerModalOpen, setIsSellerModalOpen] = useState(false);
  const [isBrandModalOpen, setIsBrandModalOpen] = useState(false);
  const [sellers, setSellers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const handleOptionChange = (option) => {
    setSelectedOption(option);
    if (option === "option1") {
      setIsSellerModalOpen(true);
    } else if (option === "option2") {
      setIsBrandModalOpen(true);
    }
  };

  const closeSellerModal = () => {
    setIsSellerModalOpen(false);
  };

  const closeBrandModal = () => {
    setIsBrandModalOpen(false);
  };

  useEffect(() => {
    const accessToken = getToken();

    const fetchSellerData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_URL}/v1/category-head/get-all-seller?page=1&limit=20&search=${searchTerm}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        if (!response.ok) {
          throw new Error("Failed to fetch seller data");
        }
        const data = await response.json();
        setSellers(data.data || []);
      } catch (error) {
        console.error("Error fetching seller data:", error);
      }
    };

    fetchSellerData();
  }, [searchTerm]); // Update useEffect dependency

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <div className="flex py-4">
      <div className="sidebar bg-blue-900 h-screen w-fit sticky top-0">
        <SideBar menu={categoryMenu} />
      </div>
      <div className="text-xs w-full px-2">
        <div className="w-full flex items-center justify-end py-4 gap-5">
          <div className="">
            <button
              onClick={() => handleOptionChange("option1")}
              className={`bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded`}
            >
              Add Seller
            </button>
          </div>
          <div className="">
            <button
              onClick={() => handleOptionChange("option2")}
              className={`bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded`}
            >
              Add Brand
            </button>
          </div>
          <input
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={handleSearchChange}
            className="border border-gray-400 rounded px-2 py-2"
          />
          <AddSellerModal
            isOpen={isSellerModalOpen}
            onClose={closeSellerModal}
            title="Add seller"
          />
          <AddBrandModal
            isOpen={isBrandModalOpen}
            onClose={closeBrandModal}
            title="Add Brand"
          />
        </div>

        <div className="container mx-auto pb-8">
          <div className="overflow-x-auto">
            <table className="table-auto w-full border-collapse">
              <thead>
                <tr className="bg-gray-200">
                  <th className="px-2 py-2">Sl. No</th>
                  <th className="px-2 py-2">Seller ID</th>
                  <th className="px-2 py-2">Seller Name</th>
                  <th className="px-2 py-2">Email</th>
                  <th className="px-2 py-2">Contact No</th>
                  <th className="px-2 py-2">Store Name</th>
                  <th className="px-2 py-2">Seller Type</th>
                  <th className="px-2 py-2">Is Verify</th>
                  <th className="px-2 py-2">CM Name</th>
                  <th className="px-2 py-2">Brand List</th>
                </tr>
              </thead>
              <tbody>
                {sellers.map((seller, index) => (
                  <SellerTableRow
                    key={seller._id}
                    seller={seller}
                    index={index}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddSellerBrand;
