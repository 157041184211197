import React, { useState, useEffect, useRef } from 'react';
import componentRegistry from './ComponentRegistry';
import { getToken } from '../../../../hook/getToken';
import { useParams, Link, useLocation } from 'react-router-dom';
import NavigateBack from '../../../../managerdashboard/NavigateBack';

const SectionLink = ({ item, currentSection }) => {
    const sectionId = item.title?.replace(/ /g, '_');
    const isActive = currentSection === sectionId;

    return (
        <Link
            className={`block text-left capitalize px-2 font-medium hover:text-blue-500  ${isActive ? ' border-l-2 border-blue-500 text-blue-500 ' : ''}`}
            key={item.title + item.index}
            to={`#${sectionId}`}
            onClick={(e) => {
                // e.preventDefault();
                document.getElementById(sectionId)?.scrollIntoView({ behavior: 'smooth' });
            }}
        >
            {item.title}
        </Link>
    );
};

const PreOrderPreview = () => {
    const { contentId } = useParams();
    const [previewData, setPreviewData] = useState([]);
    const [sideLabel, setSideLabel] = useState([]);
    const [currentSection, setCurrentSection] = useState('');
    const sectionRefs = useRef({});
    const location = useLocation();

    useEffect(() => {
        const apiUrl = `${process.env.REACT_APP_URL}/v1/pre-order/get-product-cms?preorder_session_id=${contentId}`;
        fetch(apiUrl, {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        })
            .then(res => res.json())
            .then(data => {
                setPreviewData(data?.response[0]?.data);
                setSideLabel(data?.sideLabel);
            });
    }, [contentId]);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        setCurrentSection(entry.target.id);
                    }
                });
            },
            { rootMargin: '0px 0px -50% 0px', threshold: 0.5 }
        );

        sideLabel.forEach(item => {
            const sectionId = item.title?.replace(/ /g, '_');
            const sectionElement = document.getElementById(sectionId);
            if (sectionElement) {
                sectionRefs.current[sectionId] = sectionElement;
                observer.observe(sectionElement);
            }
        });

        return () => {
            Object.values(sectionRefs.current).forEach(section => observer.unobserve(section));
        };
    }, [sideLabel]);

    useEffect(() => {
        const hash = location.hash.substring(1);
        setCurrentSection(hash);
    }, [location]);

    return (
        <>
            <div className='flex justify-start p-5'>
                <NavigateBack text='Back' />
            </div>
            <div className='flex flex-row relative'>
                <aside>
                    <div className='w-60 flex justify-center fixed top-20 left-5'>
                        <div className='flex flex-col space-y-2 h-fit'>
                            {sideLabel?.map((item, index) => (
                                <SectionLink key={index} item={{ ...item, index }} currentSection={currentSection} />
                            ))}
                        </div>
                    </div>
                </aside>
                <div className='text-left max-w-4xl mx-auto flex flex-col gap-y-10 p-5'>
                    {previewData?.map((item, index) => {
                        const ComponentToRender = componentRegistry[item.component];
                        const sectionId = item.title?.replace(/ /g, '_');
                        if (ComponentToRender) {
                            return (
                                <div key={index} id={sectionId}>
                                    <ComponentToRender data={item.data} />
                                </div>
                            );
                        } else {
                            return null;
                        }
                    })}
                </div>
            </div>
        </>
    );
};

export default PreOrderPreview;
