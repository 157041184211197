'use client'
import React, { useState, useEffect } from 'react'
import Loader from '../Loader'
import MouduleCard from './MouduleCard'
import { useCms } from '../../../context/CmsProvider'
import { getToken } from '../../../hook/getToken'
const Module = () => {
    const BASE_URL = process.env.REACT_APP_URL
    const [data, setData] = useState()
    const { isAddModuleModal, setIsAddModuleModal } = useCms()
    const [isLoading, setIsLoading] = useState(true)
    const handleClose = () => {
        setIsAddModuleModal(false)
    }
    useEffect(() => {
        const apiUrl = `${BASE_URL}/v1/pre-order/get-all-templates`;
        fetch(apiUrl,
            {
                headers: {
                    'Authorization': `Bearer ${getToken()}`
                }
            }).then(res => res.json()).then(data => {
                setIsLoading(false)
                setData(data.response)
            })

    }, [])
    console.log('data', data);
    const handleClick = (e) => {
        if (e.target.id === 'modal-container') {
            setIsAddModuleModal(false)
        }
    }

    if (!isAddModuleModal) return null
    return (
        <div
            id='modal-container'
            onClick={(e) => handleClick(e)}
            className='fixed text-xs inset-0 bg-black bg-opacity-50 backdrop-blur-sm z-50 flex justify-center items-center'>
            <div className='w-11/12 h-[35rem] flex flex-col  bg-white border-2 border-gray-600 overflow-y-scroll no-scrollbar'>
                <div className='sticky top-0 bg-gray-100 flex justify-between p-5'>
                    <h1 className='text-xl font-bold'>Add Module</h1>
                    <button onClick={() => handleClose()}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-7 h-7 text-gray-700 font-bold">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>

                <div className='flex justify-around  flex-wrap gap-7 mb-7'>
                    {
                        data?.length ?
                            data.map(item => (
                                <MouduleCard
                                    key={item._id}
                                    data={item}
                                />
                            )) : null
                    }

                    {
                        isLoading &&
                        <div className='h-60 '>
                            <Loader className={`w-20 h-20 `} />
                        </div>
                    }
                </div>


            </div>

        </div>
    )
}

export default Module