import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const SeoDataModal = ({ visible, onClose, id, type }) => {
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDesc, setMetaDesc] = useState("");
  const [metaTags, setMetaTags] = useState([]);
  const [canonicalLink, setCanonicalLink] = useState("");
  const [footerContent, setFooterContent] = useState("");
  const [h1, setH1] = useState("");
  const [bannerAltTag, setBannerAltTag] = useState("");

  useEffect(() => {
    if (id && type) {
      fetch(`${process.env.REACT_APP_URL}/v1/${type}/single-${type}/${id}`)
        .then((response) => response.json())
        .then((data) => {
          const seoData = data.result.seo_data;
          setMetaTitle(seoData.meta_title || "");
          setMetaDesc(seoData.meta_desc || "");
          setMetaTags(seoData.meta_tags || []);
          setCanonicalLink(seoData.canonical_link || "");
          setFooterContent(seoData.footer_content || "");
          setH1(seoData.h1 || "");
          setBannerAltTag(seoData.banner_alt_tag || "");
        })
        .catch((error) => {
          console.error("Error fetching SEO data:", error);
        });
    }
  }, [id, type]);

  const modules = {
    toolbar: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],  // Include headings from h1 to h6
        ['bold', 'italic', 'underline', 'strike'],  // Text styles
        [{ list: 'ordered' }, { list: 'bullet' }], // Lists
        [{ script: 'sub' }, { script: 'super' }],  // Subscript / Superscript
        [{ indent: '-1' }, { indent: '+1' }],      // Indentation
        [{ direction: 'rtl' }],                    // Text direction
        // [{ size: ['small', false, 'large', 'huge'] }],  
        [{ color: [] }, { background: [] }],       // Colors
        [{ font: [] }],                            // Font family
        [{ align: [] }],                           // Text alignment
        ['link', 'image', 'video'],                // Media
        ['clean']                                  // Remove formatting
    ],
};

  const handleSubmit = () => {
    const data = {
      [`${type}Id`]: id,
      meta_title: metaTitle,
      meta_desc: metaDesc,
      meta_tags: metaTags,
      canonical_link: canonicalLink,
      footer_content: footerContent,
      h1: h1,
      banner_alt_tag: bannerAltTag,
    };

    const accessToken = localStorage.getItem("access_token");

    fetch(`${process.env.REACT_APP_URL}/v1/${type}/add-seo-data`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${accessToken}`
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data);
        onClose();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  if (!visible) return null;

  return (
    <div className="fixed z-40 inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
      <div className="bg-white p-5 rounded-lg w-2/3 max-h-full no-scrollbar overflow-y-auto">
        <h2 className="text-xl mb-4">Add SEO Data</h2>
        <div className="mb-2">
          <label className="block text-sm text-gray-600 text-left">Meta Title</label>
          <input
            type="text"
            value={metaTitle}
            onChange={(e) => setMetaTitle(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
          />
        </div>
        <div className="mb-2">
          <label className="block text-sm text-gray-600 text-left">Meta Description</label>
          <input
            type="text"
            value={metaDesc}
            onChange={(e) => setMetaDesc(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
          />
        </div>
        <div className="mb-2">
          <label className="block text-sm text-gray-600 text-left">Meta Tags</label>
          <input
            type="text"
            value={metaTags.join(", ")}
            onChange={(e) => setMetaTags(e.target.value.split(", "))}
            className="w-full p-2 border border-gray-300 rounded"
          />
        </div>
        <div className="mb-2">
          <label className="block text-sm text-gray-600 text-left">Canonical Link</label>
          <input
            type="text"
            value={canonicalLink}
            onChange={(e) => setCanonicalLink(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
          />
        </div>
       
        <div className="mb-2">
          <label className="block text-sm text-gray-600 text-left">H1</label>
          <input
            type="text"
            value={h1}
            onChange={(e) => setH1(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
          />
        </div>
        <div className="mb-2">
          <label className="block text-sm text-gray-600 text-left">Banner Alt Tag</label>
          <input
            type="text"
            value={bannerAltTag}
            onChange={(e) => setBannerAltTag(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
          />
        </div>
        <div className="mb-2">
          <label className="block text-sm text-gray-600 text-left">Footer Content</label>
          <ReactQuill
            value={footerContent}
            onChange={setFooterContent}
            className="w-full rounded"
            modules={modules}
          />
        </div>
        <div className="flex justify-end">
          <button onClick={onClose} className="mr-2 p-2 bg-gray-300 rounded">
            Cancel
          </button>
          <button onClick={handleSubmit} className="p-2 bg-blue-500 text-white rounded">
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default SeoDataModal;
