import React, { useEffect, useState } from "react";
// import ProductIdentity from "./ProductIdentity";
// import ProductDescription from "./ProductDescription";
import FormTabs from "./FormTabs";
import SideBar from "../../../managerdashboard/Sidebar";
import { PreCM } from "../../../constant";

const Preorder = () => {
  const [productData, setProductData] = useState(null);

  return (
    <div className="flex">
      <div className="sidebar bg-[#00388c] min-h-screen w-fit sticky top-0">
        <SideBar menu={PreCM} />
      </div>
      <FormTabs />
      {/* <ProductDescription data={productData} /> */}
    </div>
  );
};

export default Preorder;
