'use client'
import React from 'react'
import { useCms } from '../../../context/CmsProvider'
const RemoveModal = ({ visible, onClose, index }) => {
    const { removeElement } = useCms()

    const handleRemove = () => {
        removeElement(index)
        onClose(false)
    }
    const handleClose = (e) => {
        const { id } = e.target
        if (id === 'container') {
            onClose(false)
        }
    }
    if (!visible) return null
    return (
        <div
            id='container'
            onClick={(e) => handleClose(e)}
            className='fixed inset-0 z-50 bg-black bg-opacity-30  backdrop-blur-sm flex justify-center items-center'>
            <div className='w-2/5 rounded bg-white '>
                <div className={` items-center justify-center  flex flex-col`}>
                    <div className='font-medium text-gray-900  text-base mt-7'>Are you sure you want to remove</div>
                    <div className='font-medium text-gray-900  text-base'>your current module ?</div>
                    <div className='flex gap-x-5 my-7'>
                        <button onClick={() => onClose()} className='py-2 px-14 bg-white text-gray-900 rounded-full border border-solid border-gray-900'>No</button>
                        <button onClick={() => handleRemove()} className='py-2 px-14 bg-red-600 text-white rounded-full'>Yes</button>
                    </div>
                </div>

            </div>

        </div>
    )
}

export default RemoveModal