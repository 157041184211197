'use client'
import React from 'react'
import { useCms } from '../../../context/CmsProvider'
const MouduleCard = ({ data }) => {
    const { addElement, elements } = useCms()
    const { _id, component, title, image_url } = data
    const handleClick = () => {
        let isIdExist = false;
        elements?.forEach(item => {
            if (item.id === _id) {
                isIdExist = true;
            }
        });
        const newElement = {
            id: isIdExist ? generateRandomId() : _id,
            component: component,
        };
        addElement(newElement);
    };

    const generateRandomId = () => {
        const chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        let randomId = '';

        for (let i = 0; i < 24; i++) {
            const randomIndex = Math.floor(Math.random() * chars.length);
            randomId += chars[randomIndex];
        }
        return randomId;
    };

    return (
        <div
            onClick={() => handleClick()}
            className='w-2/5 ring-2 ring-gray-200 hover:ring-2 hover:ring-teal-700 group cursor-pointer'>
            <h1 className='p-2 text-2xl text-gray-900 group-hover:text-white group-hover:bg-teal-700'>{title}</h1>
            <div className='w-full h-auto'>
                <img className='w-full h-full' src={image_url} alt={title} />
            </div>
        </div>
    )
}

export default MouduleCard