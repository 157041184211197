import React from 'react'
import format from 'date-fns/format'
const PendingTable = ({ sellers }) => {
  return (
    <div>
      {/* Table Section */}
      <div className="relative  overflow-hidden">
        <table className="w-full text-left text-xs">
          <thead className="bg-gray-100 text-xs font-medium uppercase text-[#666666]">
            <tr>
              <th scope="col" className="px-2 py-3">
                Onboarding Date
              </th>
              <th scope="col" className="px-2 py-3">
                Seller Name (Code)
              </th>
              <th scope="col" className="px-2 py-3">
                Business Type{" "}
              </th>
              <th scope="col" className="px-2 py-3">
                Store Name
              </th>
              <th scope="col" className="px-2 py-3">
                Email
              </th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(sellers) &&
              sellers?.map((item) => {
                const date = format(new Date(item?.createdAt), 'dd/MM/yyyy')
                return (
                  <tr key={item._id}>
                    <td className="px-2 py-3">{date}</td>
                    <td className="px-2 py-3">{item?.fullname}({item?.seller_code})</td>
                    <td className="px-2 py-3">{item?.sellerType}</td>
                    <td className="px-2 py-3">{item?.store_name}</td>
                    <td className="px-2 py-3">{item?.email}</td>
                  </tr>
                )
              })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default PendingTable