
import React, { useEffect, useMemo, useState } from 'react';
import debounce from 'lodash/debounce';
import toast from 'react-hot-toast';
import { getToken } from '../../../../hook/getToken';
import { useCms } from '../../../../context/CmsProvider';
import AddModuleButton from '../AddModuleButton';
import componentRegistry from './ComponentRegistry';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

const PreContentManager = React.memo(() => {
    const BASE_URL = process.env.REACT_APP_URL;
    const { sessionId } = useParams()
    const [params] = useSearchParams()
    const content_id = params.get('content_id')
    const isEditable = params.get('isEditable')
    const { elements, updateElements, handleForm, contentName, setContentName } = useCms();

    console.log('elements', elements);
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false)
    // Create an array to store the order in which components should be rendered
    const orderArray = useMemo(() => elements?.map((_, index) => index), [elements]);
    // Create a debounced version of the handleFormDataChange function
    const debouncedHandleFormDataChange = debounce((index, formData) => {
        const updatedElements = [...elements];
        if (formData.title) {
            updatedElements[index].title = formData.title;
        }
        updatedElements[index].data = formData;
        updateElements(updatedElements);
    }, 300);

    const handlePostData = async (url, data, token) => {

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}` // Add your token to the 'Authorization' header
        };

        const requestOptions = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(data)
        };

        try {
            const response = await fetch(url, requestOptions);
            const responseData = await response.json();
            console.log(responseData);
            if (response.ok) {
                navigate(`/preorder/cms/list/${sessionId}`)
                updateElements([])
                setContentName('')
                toast.success('Successfully Done')
                setIsLoading(false)
            } else {
                toast.error(responseData.message)
                setIsLoading(false)
            }

            // return responseData;
        } catch (error) {
            console.error('Request failed:', error);
            throw error;
        }
    };

    const handleSubmit = (e) => {
        setIsLoading(true)
        e.preventDefault();
        console.log('elements form data', elements);
        const token = getToken()

        const apiUrl = isEditable ? `${BASE_URL}/v1/pre-order/preorder-cms?content_id=${content_id}` : `${BASE_URL}/v1/pre-order/preorder-cms`

        const data = {
            content_name: contentName,
            preorder_session_id: sessionId,
            data: elements
        }
        handlePostData(apiUrl, data, token)
    };

    const [isfetching, setIsFetching] = useState(false)
    useEffect(() => {
        if (content_id && isEditable) {
            setIsFetching(true);
            const apiUrl = `${BASE_URL}/v1/pre-order/get-product-cms?contentId=${content_id}`;
            fetch(apiUrl, {
                headers: {
                    Authorization: `Bearer ${getToken()}`
                }
            })
                .then(res => res.json())
                .then(data => {
                    console.log('edit-data', data);

                    updateElements(data?.response[0]?.data);
                    setContentName(data?.response[0]?.content_name);
                    setIsFetching(false);
                })
        } else {
            updateElements([]);
            setContentName('');
        }
    }, [content_id]);

    return (
        <main className='mx-5 mb-10'>
            <form onSubmit={(e) => handleSubmit(e)} className='w-full relative'>
                <div className='sticky top-0 z-30 bg-white bg-transparent pt-2'>
                    <div className='flex flex-row justify-between mt-2'>
                        <h1 className='text-black font-medium'> Content Manager</h1>
                        <div className='flex flex-row'>
                            <button disabled={isLoading} type='submit' className='py-1 px-4 rounded bg-teal-500 text-white'>
                                {`${isEditable ? 'Update' : 'Save'}`}
                                {`${isLoading ? ' ...' : ''}`}
                            </button>
                        </div>
                    </div>

                    <div className="pt-2 pb-5 px-2 border-b-2 border-gray-600">
                        <label htmlFor="content-name" className="text-left block mb-2 text-sm  text-gray-700 ">Content name</label>
                        <input type="text" id="content-name" className="block w-52  text-gray-900 border border-gray-300 rounded-sm bg-gray-50 outline-none py-1 px-2 text-sm"
                            required
                            value={contentName}
                            onChange={(e) => setContentName(e.target.value)}
                            placeholder='Enter Content Name'
                        />
                    </div>
                </div>


                {isfetching ? (
                    <h1 className='text-center'>Loading ...</h1>
                ) : (
                    <div className='max-w-5xl mx-auto flex flex-col space-y-10 mt-10 text-left'>
                        {orderArray?.map((orderIndex) => {
                            const item = elements[orderIndex];
                            const ComponentToRender = componentRegistry[item.component];

                            if (ComponentToRender) {
                                return (
                                    <ComponentToRender
                                        key={item.id}
                                        index={orderIndex} // Use the order index as the component index
                                        initialValues={item.data}
                                        onDataChange={(formData) => debouncedHandleFormDataChange(orderIndex, formData)}
                                    />
                                );
                            }
                            return null;
                        })}

                    </div>
                )
                }

            </form>
            <div className='max-w-5xl mx-auto h-60 flex justify-center items-center border-2 border-dashed mt-10'>
                <AddModuleButton />
            </div>
        </main>
    );
});

export default PreContentManager;
