import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import PreOrderModal from "./PreOrderModal";
import toast from "react-hot-toast";
import { set } from "lodash";
import Pagination from "../../Pagination";

const PreorderVerifyList = () => {
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [expandedDescriptions, setExpandedDescriptions] = useState({});
  const [isAddingProduct, setIsAddingProduct] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [slotInformation, setSlotInformation] = useState([]);
  const [timelineInfo, setTimeLineInfo] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10; // Number of items per page

  const paginatedProducts = products.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  useEffect(() => {
    const token = localStorage.getItem("access_token");
    fetch(`${process.env.REACT_APP_URL}/v1/pre-order/get-product-list?status=active`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => setProducts(data.response));
  }, []);

  const updateProductStatus = async (productId, field, newValue) => {
    try {
      const token = localStorage.getItem("access_token");
      const response = await fetch(
        `${process.env.REACT_APP_URL}/v1/pre-order/approve-product?${field}=${newValue}&preorder_session_id=${productId}`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();

      if (response.ok) {
        setProducts((prevProducts) =>
          prevProducts.map((product) =>
            product.preorder_session_id === productId
              ? { ...product, [field]: newValue }
              : product
          )
        );
        toast.success(data.message);
      } else {
        console.error("Failed to update status");
      }
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  const handleEditClick = (productId) => {
    const token = localStorage.getItem("access_token");
    fetch(
      `${process.env.REACT_APP_URL}/v1/pre-order/get-single-product?id=${productId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setSelectedProduct(data.response);
        setIsModalOpen(true);
        setSlotInformation(data.slotInfoData);
        setTimeLineInfo(data.timelineInfo);
      })
      .catch((error) => console.error("Error fetching product data:", error));
  };

  const handleCmsEditClick = (sessionId) => {
    const cmsUrl = `/preorder/cms/preview/${sessionId}`;
    window.open(cmsUrl, "_blank");
  };

  const toggleDescription = (productId) => {
    setExpandedDescriptions((prev) => ({
      ...prev,
      [productId]: !prev[productId],
    }));
  };

  if (!products.length) {
    return <div className="text-center text-gray-500">Loading...</div>;
  }

  return (
    <div className="text-xs flex ">
      <div className="sidebar bg-[#00388c] min-h-screen w-fit sticky top-0"></div>
      <div>
        <h1 className="text-lg text-left font-bold my-4 mx-4">
          Preorder Product Details
        </h1>

        <table className="bg-white shadow-md rounded-lg overflow-hidden mx-4">
          <thead>
            <tr className="bg-gray-200">
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Main Image
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Item Name
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Description
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                MRP
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Approval Status
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {paginatedProducts.map((product) => (
              <tr key={product._id} className="border-t align-top">
                <td className="px-6 py-4 align-top">
                  <img
                    src={product?.main_img}
                    alt={product.item_name}
                    className="w-24 h-auto object-cover"
                  />
                </td>
                <td className="px-6 py-4 align-top">{product.item_name}</td>
                <td className="px-6 py-4 align-top">
                  {expandedDescriptions[product._id]
                    ? product.description
                    : `${product.description.substring(0, 100)}${
                        product.description.length > 100 ? "..." : ""
                      }`}
                  {product.description.length > 100 && (
                    <button
                      onClick={() => toggleDescription(product._id)}
                      className="text-blue-500 ml-2"
                    >
                      {expandedDescriptions[product._id]
                        ? "View Less"
                        : "View More"}
                    </button>
                  )}
                </td>
                <td className="px-6 py-6 align-top">{product.mrp}</td>
                <td className="px-6 py-6 align-top">
                  <span
                    className={`px-4 py-2 rounded ${
                      product.approval_status === "approved"
                        ? "border-green-500 bg-green-200 text-green-800"
                        : product.approval_status === "rejected"
                        ? "border-red-500 bg-red-200 text-red-800"
                        : "bg-gray-200 border-gray-500 text-gray-800"
                    }`}
                  >
                    {product.approval_status.charAt(0).toUpperCase() +
                      product.approval_status.slice(1)}
                  </span>
                </td>
                <td className="px-6 py-4 flex items-start gap-2 align-top">
                  <button
                    onClick={() => handleEditClick(product._id)}
                    className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                  >
                    View
                  </button>
                  <button
                    onClick={() =>
                      handleCmsEditClick(product.preorder_session_id)
                    }
                    className="px-2 py-2 text-nowrap bg-green-500 text-white rounded hover:bg-green-600"
                  >
                    A+ CMS
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className="mt-4 mx-4">
          <Pagination
            setCurrentPage={setCurrentPage}
            totalItems={products.length}
            pageSize={pageSize}
            currentPage={currentPage}
          />
        </div>

        {isModalOpen && (
          <PreOrderModal
            product={selectedProduct}
            slotInformation={slotInformation}
            timelineInfo={timelineInfo}
            onClose={() => setIsModalOpen(false)}
            updateProductStatus={updateProductStatus}
          />
        )}
      </div>
    </div>
  );
};

export default PreorderVerifyList;
