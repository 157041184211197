import React, { useState, useEffect } from "react";
import { getToken } from "../../hook/getToken"; // Import getToken function

const AddBrandListModal = ({ isOpen, onClose, sellerId }) => {
  const [brands, setBrands] = useState([]);

  useEffect(() => {
    const fetchBrandList = async () => {
      try {
        const accessToken = getToken(); // Get access token
        const response = await fetch(
          `${process.env.REACT_APP_URL}/v1/category-head/seller-brands?status=Approved&seller_id=${sellerId}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`, // Include access token in headers
            },
          }
        );
        if (!response.ok) {
          throw new Error("Failed to fetch brand list");
        }
        const data = await response.json();
        setBrands(data.brands || []);
      } catch (error) {
        console.error("Error fetching brand list:", error);
      }
    };

    if (isOpen) {
      fetchBrandList();
    }
  }, [isOpen, sellerId]);

  const handleCloseModal = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div
      className={`modal ${isOpen ? "flex" : "hidden"
        } fixed inset-0 items-center justify-center bg-gray-500 bg-opacity-75 overflow-y-auto`}
      onClick={handleCloseModal}
    >
      <div className="modal-dialog">
        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
          <div className="modal-body bg-white p-5 rounded-lg max-h-96 overflow-y-auto">
            {/* Check if brands array is empty */}
            {brands.length > 0 ? (
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {brands.map((brand) => (
                  <div
                    key={brand._id}
                    className="bg-white rounded-lg shadow-lg overflow-hidden p-2"
                  >
                    <img
                      src={brand.brand_logo_url}
                      alt={brand.brand_name}
                      className="w-full h-24 object-contain mb-2"
                    />
                    <div className="text-center">
                      <h6 className="text-sm font-semibold">{brand.brand_name}</h6>
                      <p className="text-xs text-gray-600">{brand.trademark_office}</p>
                      <p className="text-xs text-gray-600">
                        Trademark Reg No: {brand.trademark_reg_no}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="text-center text-gray-700 p-4">
                <p>No brands available for this seller.</p>
              </div>
            )}
            <div className="modal-footer flex justify-end">
              <button
                type="button"
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                onClick={onClose}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddBrandListModal;
