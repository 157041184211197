import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { PreCM } from "../../../constant";
import SideBar from "../../../managerdashboard/Sidebar";
import toast from "react-hot-toast";

const PreProductList = () => {
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [expandedDescriptions, setExpandedDescriptions] = useState({});
  const [isAddingProduct, setIsAddingProduct] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("access_token");
    fetch(`${process.env.REACT_APP_URL}/v1/pre-order/get-product-list`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => setProducts(data.response));
  }, []);

  const updateProductApprovalStatus = async (productId, field, newValue) => {
    try {
      const token = localStorage.getItem("access_token");
      const response = await fetch(
        `${process.env.REACT_APP_URL}/v1/pre-order/approve-product?${field}=${newValue}&preorder_session_id=${productId}`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        setProducts((prevProducts) =>
          prevProducts.map((product) =>
            product.preorder_session_id === productId
              ? { ...product, [field]: newValue }
              : product
          )
        );
      } else {
        console.error("Failed to update status");
      }
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  const updateProductStatus = async (productId, field, newValue) => {
    try {
      const token = localStorage.getItem("access_token");

      // Handle "draft" to "active" transition
      if (newValue === "active") {
        const product = products.find(
          (product) => product.preorder_session_id === productId
        );
        if (product?.status === "draft") {
          // First set status to "inactive"
          const inactiveResponse = await fetch(
            `${process.env.REACT_APP_URL}/v1/pre-order/update-status?${field}=inactive&preorder_session_id=${productId}`,
            {
              method: "PUT",
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          if (!inactiveResponse.ok) {
            console.error("Failed to update status to inactive");
            return;
          }
        }
      }

      // Update to the desired status
      const response = await fetch(
        `${process.env.REACT_APP_URL}/v1/pre-order/update-status?${field}=${newValue}&preorder_session_id=${productId}`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      if (response.ok) {
        setProducts((prevProducts) =>
          prevProducts.map((product) =>
            product.preorder_session_id === productId
              ? { ...product, [field]: newValue }
              : product
          )
        );
        toast.success(data.message);
      } else {
        console.error("Failed to update status");
      }
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  const handleEditClick = (productId) => {
    const token = localStorage.getItem("access_token");
    fetch(
      `${process.env.REACT_APP_URL}/v1/pre-order/get-single-product?id=${productId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        console.log("Navigating to /preorder-edit with data:", data.response); // Debug statement
        navigate(`/preorder-edit/${productId}`, { state: { product: data.response } });
      })
      .catch((error) => console.error("Error fetching product data:", error));
  };

  const handleCmsEditClick = (sessionId) => {
    navigate(`/preorder/cms/list/${sessionId}`);
  };

  const toggleDescription = (productId) => {
    setExpandedDescriptions((prev) => ({
      ...prev,
      [productId]: !prev[productId],
    }));
  };

  const handleAddProductClick = () => {
    setIsAddingProduct(true);
    localStorage.removeItem("preorder_session_id");
    setTimeout(() => {
      navigate("/preorder");
    }, 2000);
  };

  if (!products.length) {
    return <div className="text-center text-gray-500">Loading...</div>;
  }

  return (
    <div className="text-xs flex overflow-x-scroll">
      <div className="sidebar bg-[#00388c] min-h-screen w-fit sticky top-0">
        <SideBar menu={PreCM} />
      </div>
      <div>
        
        <div className="flex justify-between px-5 pt-5">
        <h1 className="text-lg font-bold mb-4">Product Details</h1>
          <Link to="#" onClick={handleAddProductClick}>
            <button
              className={`bg-blue-500 mb-2 mr-4 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ${isAddingProduct ? 'opacity-50 cursor-not-allowed' : ''}`}
              disabled={isAddingProduct}
            >
              {isAddingProduct ? "Redirecting..." : "Add Product"}
            </button>
          </Link>
        </div>
        <table className=" bg-white shadow-md rounded-lg overflow-hidden mx-4 ">
          <thead>
            <tr className="bg-gray-200">
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Main Image
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Item Name
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Description
              </th>

              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                MRP
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Approval Status
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Status
              </th>
              <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(products) &&
              products.map((product) => (
                <tr key={product._id} className="border-t">
                  <td className="px-6 py-4">
                    <img
                      src={product?.main_img}
                      alt={product.item_name}
                      className="w-24 h-auto object-cover"
                    />
                  </td>
                  <td className="px-6 py-4">{product.item_name}</td>
                  <td className="px-6 py-4">
                    {expandedDescriptions[product._id]
                      ? product.description
                      : `${product.description.substring(0, 100)}${product.description.length > 100 ? '...' : ''}`}
                    {product.description.length > 100 && (
                      <button
                        onClick={() => toggleDescription(product._id)}
                        className="text-blue-500 ml-2"
                      >
                        {expandedDescriptions[product._id] ? "View Less" : "View More"}
                      </button>
                    )}
                  </td>
                  <td className="px-6 py-4">{product.mrp}</td>
                  <td className="px-6 py-4">
                    <span
                      className={`px-4 py-2 rounded border ${
                        product.approval_status === "approved"
                          ? "bg-green-100 border-green-500 text-green-700"
                          : "bg-gray-100 border-gray-300 text-gray-700"
                      }`}
                    >
                      {product.approval_status.charAt(0).toUpperCase() + product.approval_status.slice(1)}
                    </span>
                  </td>
                  <td className="px-6 py-4">
                    <select
                      value={product.status}
                      onChange={(e) =>
                        updateProductStatus(product.preorder_session_id, "status", e.target.value)
                      }
                      className="px-4 py-2 rounded border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 bg-white text-gray-700"
                    >
                      <option value="">Draft</option>
                      <option value="active">Active</option>
                      <option value="inactive">Inactive</option>
                    </select>
                  </td>
                  <td className="px-6 py-6 flex items-center gap-2">
                    <button
                      onClick={() => handleEditClick(product.preorder_session_id)}
                      className="px-4 py-2 bg-blue-500 text-nowrap text-white rounded hover:bg-blue-600"
                    >
                      Edit Pre-order
                    </button>
                    <button
                      onClick={() =>
                        handleCmsEditClick(product.preorder_session_id)
                      }
                      className="px-4 py-2 text-nowrap bg-green-500 text-white rounded hover:bg-green-600"
                    >
                      Edit CMS
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PreProductList;
