import React, { useState } from "react";

const PreOrderModal = ({ product, slotInformation, timelineInfo, onClose, updateProductStatus }) => {
  const [approvalStatus, setApprovalStatus] = useState(product.approval_status);
  console.log("Product>>>", product);
  console.log("time line info>>>", timelineInfo);
  const handleStatusChange = async (newStatus) => {
    await updateProductStatus(product.preorder_session_id, "approval_status", newStatus);
    setApprovalStatus(newStatus); // Update the local state after the API call
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "approved":
        return "text-green-800";
      case "rejected":
        return "text-red-800";
      case "pending":
        return "text-yellow-800";
      default:
        return "text-gray-800";
    }
  };

  if (!product) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white rounded-lg shadow-lg w-3/4 max-h-[90vh] overflow-hidden">
        <div className="flex justify-between items-center p-6 border-b border-gray-300 sticky top-0 bg-white z-10">
          <h2 className="text-xl font-bold">{product.item_name}</h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            ✖
          </button>
        </div>
        <div className="p-3 overflow-y-auto max-h-[calc(90vh-180px)]">
          <div className="text-start my-4">
            <span className="font-semibold">Approval Status:</span>{" "}
            <span className={getStatusColor(approvalStatus)}>
              {approvalStatus.charAt(0).toUpperCase() + approvalStatus.slice(1)}
            </span>
          </div>
          <div className="space-y-6 text-left">
            <div>
              <img
                src={product.banner}
                alt={product.item_name}
                className="w-full h-auto object-cover rounded-lg"
              />
            </div>
            <div className="w-1/2 grid grid-cols-2 gap-3">
              <div className="col-span-2">
                <strong>Item Name Heading:</strong> {product.item_name_heading}
              </div>
              <div className="col-span-2">
                <strong>Description:</strong> {product.description}
              </div>
              <div>
                <strong>MRP:</strong> ₹{product.mrp}
              </div>
              <div>
                <strong>SKU:</strong> {product.product_sku}
              </div>
              <div>
                <strong>Status:</strong> {product.status}
              </div>
              <div>
                <strong>EAN/GTIN:</strong> {product.ean_gtin}
              </div>
            </div>
            <div>
              <strong>Bullet Points:</strong>
              <ul className="list-disc ml-6">
                {product.bullet_points.map((point, index) => (
                  <li key={index}>{point}</li>
                ))}
              </ul>
            </div>
            <div>
              <strong>Product Images:</strong>
              <div className="grid grid-cols-5 gap-4 mt-2">
                {Object.entries(product.images).map(([key, value]) =>
                  key.includes("public_id") ? null : (
                    <div key={key} className="flex flex-col items-center">
                      <img
                        src={value}
                        alt={key}
                        className="w-full h-auto object-cover rounded-lg"
                      />
                    </div>
                  )
                )}
              </div>
            </div>
            {product.has_variation && (
              <div>
                <strong>Product Variations:</strong>
                <div className="mt-2">
                  {product.productVariations && product.productVariations.length > 0 ? (
                    <table className="table-auto w-full border-collapse border border-gray-300">
                      <thead>
                        <tr className="bg-gray-200">
                          <th className="border border-gray-300 px-4 py-2 text-left">SKU</th>
                          <th className="border border-gray-300 px-4 py-2 text-left">{product.productVariations[0]?.name || "Attribute 1"}</th>
                          <th className="border border-gray-300 px-4 py-2 text-left">{product.productVariations[0]?.name2 || "Attribute 2"}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {product.productVariations.map((variation) => (
                          <tr key={variation._id}>
                            <td className="border border-gray-300 px-4 py-2">{variation.product_sku}</td>
                            <td className="border border-gray-300 px-4 py-2">{variation.value || "N/A"}</td>
                            <td className="border border-gray-300 px-4 py-2">{variation.value2 || "N/A"}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <p className="text-gray-500">No variations available.</p>
                  )}
                </div>
              </div>
            )}
            <div>
              <strong>Slot Information:</strong>
              <div className="mt-2">
                {slotInformation?.slot_info && slotInformation?.slot_info.length > 0 ? (
                  <table className="table-auto w-full border-collapse border border-gray-300">
                    <thead>
                      <tr className="bg-gray-200">
                        <th className="border border-gray-300 px-4 py-2 text-left">Label</th>
                        <th className="border border-gray-300 px-4 py-2 text-left">Description</th>
                        <th className="border border-gray-300 px-4 py-2 text-left">Min Limit</th>
                        <th className="border border-gray-300 px-4 py-2 text-left">Max Limit</th>
                        <th className="border border-gray-300 px-4 py-2 text-left">Percent Off</th>
                        <th className="border border-gray-300 px-4 py-2 text-left">List Price</th>
                        <th className="border border-gray-300 px-4 py-2 text-left">Sold Qty</th>
                        <th className="border border-gray-300 px-4 py-2 text-left">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {slotInformation?.slot_info.map((slot) => (
                        <tr key={slot._id}>
                          <td className="border border-gray-300 px-4 py-2">{slot.label}</td>
                          <td className="border border-gray-300 px-4 py-2">{slot.description}</td>
                          <td className="border border-gray-300 px-4 py-2">{slot.min_limit}</td>
                          <td className="border border-gray-300 px-4 py-2">{slot.max_limit}</td>
                          <td className="border border-gray-300 px-4 py-2">{slot.percent_off}%</td>
                          <td className="border border-gray-300 px-4 py-2">₹{slot.list_price}</td>
                          <td className="border border-gray-300 px-4 py-2">{slot.sold_qty}</td>
                          <td className="border border-gray-300 px-4 py-2">{slot.status}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <p className="text-gray-500">No slot information available.</p>
                )}
              </div>
            </div>
            <div className="mt-6">
              <strong>Timeline Information:</strong>
              <div className="mt-2">
                {timelineInfo?.timeline_data && timelineInfo.timeline_data.length > 0 ? (
                  <table className="table-auto w-full border-collapse border border-gray-300">
                    <thead>
                      <tr className="bg-gray-200">
                        <th className="border border-gray-300 px-4 py-2 text-left">Label</th>
                        <th className="border border-gray-300 px-4 py-2 text-left">Description</th>
                        <th className="border border-gray-300 px-4 py-2 text-left">Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {timelineInfo?.timeline_data.map((timeline) => (
                        <tr key={timeline._id}>
                          <td className="border border-gray-300 px-4 py-2">{timeline.label}</td>
                          <td className="border border-gray-300 px-4 py-2">{timeline.description}</td>
                          <td className="border border-gray-300 px-4 py-2">{new Date(timeline.date).toLocaleDateString()}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <p className="text-gray-500">No timeline information available.</p>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-center items-center p-6 border-t border-gray-300 bg-white sticky bottom-0">
          <button
            onClick={() => handleStatusChange("approved")}
            className={`px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 mr-2 ${
              approvalStatus === "approved" ? "opacity-50 cursor-not-allowed" : ""
            }`}
            disabled={approvalStatus === "approved"}
          >
            Approve
          </button>
          <button
            onClick={() => handleStatusChange("rejected")}
            className={`px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 ${
              approvalStatus === "rejected" ? "opacity-50 cursor-not-allowed" : ""
            }`}
            disabled={approvalStatus === "rejected"}
         >
            Reject
          </button>
        </div>
      </div>
    </div>
  );
};

export default PreOrderModal;
