import React, { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import QuillEditor from '../seo-data/QuillEditor';
import { getToken } from '../../hook/getToken';
const AddContentModal = ({ id, setId }) => {
    const [htmlContent, setHtmlContent] = useState()

    useEffect(() => {
        const getData = async (sessionId) => {
            const res = await fetch(`${process.env.REACT_APP_URL}/v1/cms/get-product-seo-content?session_id=${sessionId}`)
            const data = await res.json()
            return data.data;
        }
        getData(id).then(d => setHtmlContent(d?.content))
    }, [id])

    const handleSubmit = async (e) => {
        e.preventDefault();
        const payload = {
            session_id: id,
            content: htmlContent
        };
        try {
            const response = await fetch(`${process.env.REACT_APP_URL}/v1/cms/add-product-seo-content`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${getToken()}`
                },
                body: JSON.stringify(payload),
            });
            if (response.ok) {
                // Handle successful response
                toast.success('Product Content Added Successfully');
                setId(null);
            } else {
                // Handle error response
                console.error('Failed to add meta tags');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <div className={`fixed z-10 inset-0 overflow-y-auto ${id ? 'block' : 'hidden'}`}>
            <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div className="fixed inset-0 transition-opacity" aria-hidden="true" onClick={() => setId(null)}>
                    <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                </div>
                <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true"></span>
                <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-5xl sm:w-full">
                    <form onSubmit={handleSubmit}>
                        <h1 className="text-xl font-bold px-4 py-4">Add Product Content</h1>
                        <div className="grid grid-cols-1 gap-2">
                            <div className='px-4 py-1'>
                                <QuillEditor value={htmlContent} onChange={setHtmlContent} />
                            </div>
                        </div>
                        <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                            <button
                                type="submit"
                                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                            >
                                Submit
                            </button>
                            <button
                                type="button"
                                onClick={() => setId(null)}
                                className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                            >
                                Cancel
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default AddContentModal;
