import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

const SlotInformation = ({ goToNextTab }) => {
  const { id } = useParams();
  const preorder_session_id = localStorage.getItem("preorder_session_id");  
  const [slots, setSlots] = useState([
    {
      id: 1,
      file: null,
      imgUrl: "",
      label: "",
      description: "",
      minLimit: "",
      maxLimit: "",
      percentOff: "",
      listPrice: "",
      startDate: "",
      endDate: "",
    },
  ]);
  const [thresholdQty, setThresholdQty] = useState(""); // Separate state for threshold quantity
  const [fundingGoal, setFundingGoal] = useState(""); // Separate state for funding goal
  const accessToken = localStorage.getItem("access_token");

  useEffect(() => {
    const fetchSlotInfo = async () => {
      try {
        const response = await axios.get(
          `https://api.21genx.com:8000/v1/pre-order/get-slot-info?preorder_session_id=${id}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "Content-Type": "application/json",
            },
          }
        );

        const fetchedSlots = response.data.response[0].slot_info.map(
          (slot, index) => ({
            id: index + 1,
            file: null,
            imgUrl: slot.img || "",
            label: slot.label,
            description: slot.description,
            minLimit: slot.min_limit,
            maxLimit: slot.max_limit,
            percentOff: slot.percent_off,
            listPrice: slot.list_price,
            startDate: slot.start_date.split("T")[0],
            endDate: slot.end_date.split("T")[0],
          })
        );

        setThresholdQty(response.data.response[0].threshold_qty || ""); // Set threshold quantity
        setFundingGoal(response.data.response[0].funding_goal || ""); // Set funding goal

        if (fetchedSlots.length === 0) {
          setSlots([
            {
              id: 1,
              file: null,
              imgUrl: "",
              label: "",
              description: "",
              minLimit: "",
              maxLimit: "",
              percentOff: "",
              listPrice: "",
              startDate: "",
              endDate: "",
            },
          ]);
        } else {
          setSlots(fetchedSlots);
        }
      } catch (error) {
        console.error("Error fetching slot info:", error);
        setSlots([
          {
            id: 1,
            file: null,
            imgUrl: "",
            label: "",
            description: "",
            minLimit: "",
            maxLimit: "",
            percentOff: "",
            listPrice: "",
            startDate: "",
            endDate: "",
          },
        ]);
      }
    };

    fetchSlotInfo();
  }, [id, accessToken]);

  const handleAddSlot = () => {
    setSlots([
      ...slots,
      {
        id: slots.length + 1,
        file: null,
        imgUrl: "",
        label: "",
        description: "",
        minLimit: "",
        maxLimit: "",
        percentOff: "",
        listPrice: "",
        startDate: "",
        endDate: "",
      },
    ]);
  };

  const handleRemoveSlot = (id) => {
    if (slots.length > 1) {
      setSlots(slots.filter((slot) => slot.id !== id));
    }
  };

  const handleInputChange = (e, id, field) => {
    const updatedSlots = slots.map((slot) =>
      slot.id === id ? { ...slot, [field]: e.target.value } : slot
    );
    setSlots(updatedSlots);
  };

  const handleFileChange = (event, id) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const updatedSlots = slots.map((slot) =>
          slot.id === id ? { ...slot, file, imgUrl: reader.result } : slot
        );
        setSlots(updatedSlots);
      };
      reader.readAsDataURL(file);
    }
  };

  const handlePercentOffChange = async (e, id) => {
    const percentOff = e.target.value;
    const updatedSlots = slots.map((slot) =>
      slot.id === id ? { ...slot, percentOff } : slot
    );
    setSlots(updatedSlots);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/v1/pre-order/price-calculate`,
        {
          preorder_session_id: id,
          percent_off: percentOff,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      const newListPrice = response.data.list_price;
      const updatedSlotsWithPrice = updatedSlots.map((slot) =>
        slot.id === id ? { ...slot, listPrice: newListPrice } : slot
      );
      setSlots(updatedSlotsWithPrice);
    } catch (error) {
      console.error("Error calculating price:", error);
    }
  };

  const handleSubmit = async ({ actionType, goToNextTab }) => {
    const slotInfo = slots.map((slot) => ({
      img: slot.file ? slot.imgUrl : slot.imgUrl,
      label: slot.label,
      description: slot.description,
      min_limit: 1,
      max_limit: slot.maxLimit,
      percent_off: slot.percentOff,
      list_price: slot.listPrice,
      start_date: slot.startDate,
      end_date: slot.endDate,
    }));

    const apiBody = {
      preorder_session_id: preorder_session_id || id,
      threshold_qty: thresholdQty, // Include threshold quantity
      funding_goal: fundingGoal, // Include funding goal
      slot_info: slotInfo,
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/v1/pre-order/add-slot-info`,
        apiBody,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Response:", response.data);
      if (actionType === "next" && typeof goToNextTab === "function") {
        goToNextTab();
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div className="p-4">
      <h2 className="text-left text-xl font-semibold mb-4 text-blue-600">
        Slot Information
      </h2>
      <div className="grid grid-cols-2 gap-4 p-4">
      <div className="col-span-1">
        <label className="block font-semibold text-sm text-left">
          Threshold Quantity: *
        </label>
        <input
          type="number"
          className="w-full p-2 border border-gray-300 rounded text-sm h-8"
          placeholder="Threshold Quantity"
          value={thresholdQty}
          onChange={(e) => setThresholdQty(e.target.value)}
        />
      </div>
      <div className="col-span-1">
        <label className="block font-semibold text-sm text-left">
          Funding Goal: *
        </label>
        <input
          type="number"
          className="w-full p-2 border border-gray-300 rounded text-sm h-8"
          placeholder="Funding Goal"
          value={fundingGoal}
          onChange={(e) => setFundingGoal(e.target.value)}
        />
      </div>
      </div>
      {slots.map((slot) => (
        <div key={slot.id} className="mb-4 text-xs p-4 ">
          <div className="flex gap-4 mb-4">
            <div className="flex flex-col w-1/3">
              <label className="text-left mb-1 font-semibold">Label:</label>
              <input
                type="text"
                className="p-2 border rounded h-8"
                value={slot.label}
                onChange={(e) => handleInputChange(e, slot.id, "label")}
              />
            </div>
            <div className="flex flex-col w-full">
              <label className="text-left mb-1 font-semibold">
                Description:
              </label>
              <input
                type="text"
                className="p-2 border rounded h-8"
                value={slot.description}
                onChange={(e) => handleInputChange(e, slot.id, "description")}
              />
            </div>
          </div>
          <div className="grid grid-cols-3 gap-4 mb-4">
            <div className="flex flex-col border rounded-lg p-4">
              <label className="text-left mb-1 font-semibold">Limit:</label>
              <div className="flex flex-col">
                <label className="text-left mb-1">Max</label>
                <input
                  type="number"
                  className="p-2 border rounded h-8"
                  value={slot.maxLimit}
                  onChange={(e) => handleInputChange(e, slot.id, "maxLimit")}
                />
              </div>
            </div>
            <div className="flex flex-col border rounded-lg p-4">
              <label className="text-left mb-1 font-semibold">Offer:</label>
              <div className="flex gap-2">
                <div className="flex flex-col">
                  <label className="text-left mb-1">%</label>
                  <input
                    type="number"
                    className="p-2 border rounded h-8"
                    value={slot.percentOff}
                    onChange={(e) => handlePercentOffChange(e, slot.id)}
                  />
                </div>
                <div className="flex flex-col">
                  <label className="text-left mb-1">List</label>
                  <input
                    type="number"
                    className="p-2 border rounded h-8"
                    value={slot.listPrice}
                    onChange={(e) => handleInputChange(e, slot.id, "listPrice")}
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-col border rounded-lg p-4">
              <label className="text-left mb-1 font-semibold">Duration:</label>
              <div className="flex gap-2">
                <div className="flex flex-col">
                  <label className="text-left mb-1">Start</label>
                  <input
                    type="date"
                    className="p-2 border rounded h-8"
                    value={slot.startDate}
                    onChange={(e) => handleInputChange(e, slot.id, "startDate")}
                  />
                </div>
                <div className="flex flex-col">
                  <label className="text-left mb-1">End</label>
                  <input
                    type="date"
                    className="p-2 border rounded h-8"
                    value={slot.endDate}
                    onChange={(e) => handleInputChange(e, slot.id, "endDate")}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center gap-4 mb-4">
            {slot.imgUrl && (
              <img src={slot.imgUrl} alt="Slot" className="w-20 h-20 mr-4" />
            )}
            <input
              type="file"
              className="p-2 border rounded h-8"
              onChange={(e) => handleFileChange(e, slot.id)}
            />
            <button
              className="py-1 px-2 bg-gray-300 text-gray-600 rounded"
              onClick={() => handleRemoveSlot(slot.id)}
              disabled={slots.length === 1}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M19.5 6l-1.112 13.226A2.25 2.25 0 0116.148 21H7.852a2.25 2.25 0 01-2.24-1.774L4.5 6m0 0H1.875M4.5 6h15M19.5 6H22.125M7.875 6V4.875A1.875 1.875 0 019.75 3h4.5A1.875 1.875 0 0116.125 4.875V6M9.75 11.25v5.25m4.5-5.25v5.25"
                />
              </svg>
            </button>
          </div>
        </div>
      ))}
      <button
        className="p-2 bg-blue-500 text-white rounded h-8 mb-4"
        onClick={handleAddSlot}
      >
        + Add slot
      </button>
      <div className="flex justify-end gap-4">
        <button
          className="p-2 bg-gray-500 text-white rounded"
          onClick={handleSubmit}
        >
          Save as Draft
        </button>
        <button
          className="p-2 bg-blue-500 text-white rounded"
          onClick={(e) => handleSubmit({ e, actionType: "next", goToNextTab })}
        >
          Save & Next
        </button>
      </div>
    </div>
  );
};

export default SlotInformation;