"use client";
import React, { useState, useEffect } from "react";
import SideBar from "../Sidebar";
import { categoryManagerMenu } from "../../constant";
import toast from "react-hot-toast";
import { getToken } from "../../hook/getToken";
import { User, Mail, Store, Phone, Lock, CreditCard, House } from "lucide-react";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader";
export const SignupForm = () => {
  const navigate = useNavigate()
  const [access_token, setToken] = useState(null);
  const [states, setStates] = useState([]);
  const [isPending, setIsPending] = useState(false)
  const [formData, setFormData] = useState({
    sellerType: "startup",
    fullname: "",
    email: "",
    phone: "",
    password: "",
    cnfpassword: "",
    store_name: "",
    gst_number: "",
    pan_number: "",
    address_line1: "",
    address_line2: "",
    country_name: "India",
    state_name: "",
    city_name: "",
    zip_code: "",
    panImageUrl: "",
    gstImageUrl: ""
  });
  const [errors, setErrors] = useState({});
  useEffect(() => {
    if (typeof window !== "undefined") {
      setToken(getToken());
    }
    fetchStates();
  }, []);

  const fetchStates = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_URL}/v1/state/all-state`);
      const data = await res.json();
      if (data.success) setStates(data.data);
    } catch (error) {
      console.error("Error fetching states:", error);
    }
  };

  const validateField = (name, value) => {
    console.log('name value', name, value);
    let message = "";
    const passwordRegx =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,15}$/;
    const gstRegx =
      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/;
    const panRegx = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
    const emailRegx = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegx = /^\+91[6-9]\d{9}$/;

    const pincodeRegex = /^\d{6}$/;
    if (name === 'city_name' || name === 'country_name' || name === 'state_name') return
    if (!value) {
      message = `${name.replace("_", " ")} is required.`;
    } else {
      if (name === "email" && !emailRegx.test(value)) message = "Invalid email format.";
      if (name === "phone" && !phoneRegx.test(value)) message = "Invalid phone number";
      if (name === "password" && !passwordRegx.test(value)) message = "Weak password.";
      if (name === "gst_number" && !gstRegx.test(value)) message = "Invalid GST number.";
      if (name === "pan_number" && !panRegx.test(value)) message = "Invalid PAN number.";
      if (name === "cnfpassword" && value !== formData.password) message = "Passwords do not match.";
      if (name === "zip_code" && !pincodeRegex.test(value)) message = "Invalid Pincode";
    }
    setErrors((prev) => ({ ...prev, [name]: message }));
  };

  const handleChange = (e) => {
    let { name, value, type } = e.target;
    if (name === 'gst_number' || name === 'pan_number') {
      value = value.toUpperCase()
    }
    if (type === 'file') {
      return setFormData((prev) => ({ ...prev, [name]: e.target.files[0] }));
    }
    setFormData((prev) => ({ ...prev, [name]: value }));
    validateField(name, value);
  };



  const handleSubmit = async (e) => {
    setIsPending(true)
    e.preventDefault();
    console.log('formdata', formData);
    // Validate all fields before submitting
    delete formData.cnfpassword
    Object.keys(formData).forEach((field) => validateField(field, formData[field]));

    if (Object.values(errors).some((msg) => console.log('message', msg))) {
      toast.error(`Please fix the errors before submitting.`);
      return;
    }

    const formDataObj = new FormData();
    Object.entries(formData).forEach(([key, value]) => formDataObj.append(key, value));

    try {
      const response = await fetch(`${process.env.REACT_APP_URL}/v1/category-manager/sellerCompleteSignupByCm`, {
        method: "POST",
        body: formDataObj,
        headers: { Authorization: `Bearer ${access_token}` },
      });

      const responseData = await response.json();
      if (response.ok) {
        toast.success(responseData.message);
        setFormData({ ...formData, password: "", cnfpassword: "" });
        navigate('/category-manager-dashboard')
      } else {
        toast.error(responseData.messages);
      }
    } catch (error) {
      console.error("Signup error:", error);
      toast.error("An error occurred during registration.");
    } finally {
      setIsPending(false)
    }
  };

  return (
    <div className="flex w-full max-w-screen-2xl mx-auto">
      {/* Sidebar */}
      <div className="sidebar bg-[#00388c] h-screen w-fit sticky top-0">
        <SideBar menu={categoryManagerMenu} />
      </div>

      {/* Main Form */}
      <div className="flex-1 max-w-4xl mx-auto p-5">
        <form className="mx-auto" onSubmit={handleSubmit}>
          <h1 className="text-4xl font-bold text-center">Hello!</h1>
          <h3 className="text-2xl font-semibold pb-4 pt-3 text-center">Sign Up to Get Started</h3>

          {/* Seller Type */}
          <section className="flex justify-center mb-5">
            <div className="flex rounded-full bg-gray-200">
              {["startup", "business"].map((type) => (
                <button
                  key={type}
                  type="button"
                  className={`py-2 w-52 rounded-full ${formData.sellerType === type ? "bg-blue-500 text-white" : ""}`}
                  onClick={() => setFormData((prev) => ({ ...prev, sellerType: type }))}
                >
                  {type === "startup" ? "Indian Brand" : "International Brand"}
                </button>
              ))}
            </div>
          </section>

          {/* Inputs */}
          <div className="grid grid-cols-2 gap-x-5 gap-y-2">
            {[
              { name: "fullname", icon: User, placeholder: "Full Name" },
              { name: "email", icon: Mail, placeholder: "Email" },
              { name: "store_name", icon: Store, placeholder: "Store Name" },
              { name: "phone", icon: Phone, placeholder: "Mobile Number" },
              { name: "password", icon: Lock, placeholder: "Password", type: "password" },
              { name: "cnfpassword", icon: Lock, placeholder: "Confirm Password", type: "password" },
              { name: "gst_number", icon: CreditCard, placeholder: "GST Number" },
              { name: "pan_number", icon: CreditCard, placeholder: "PAN Number" },
              { name: "address_line1", icon: House, placeholder: "Address" },
              { name: "address_line2", icon: House, placeholder: "Locality" },
              { name: "zip_code", icon: House, placeholder: "Pincode" },
              { name: "city_name", icon: House, placeholder: "City" },
            ].map(({ name, icon, placeholder, type = "text" }) => (
              <div key={name}>
                <IconInput type={type} icon={icon} name={name} placeholder={placeholder} value={formData[name]} onChange={handleChange} className="input-class" />
                {errors[name] && <p className="text-red-500 text-start">{errors[name]}</p>}
              </div>
            ))}
          </div>

          <div className="grid grid-cols-2 gap-5 mb-2">
            <select
              required
              onChange={(e) => handleChange(e)}
              value={formData?.state_name || ""}
              name="state_name"
              className="flex gap-2   border rounded-lg w-full py-2 px-3 text-[#c2c2c2] leading-tight focus:outline-none focus:outline">
              <option disabled>--select--</option>
              {
                states?.map(d => (
                  <option className="text-gray-500">{d.state_name}</option>
                ))
              }
            </select>
            <select className="flex gap-2   border rounded-lg w-full py-2 px-3 text-[#c2c2c2] leading-tight focus:outline-none focus:outline">
              <option>India</option>
            </select>

          </div>

          <div className="grid grid-cols-2 gap-5 mb-5">
            <div>
              <label class="text-start block mb-2 text-sm 5 font-normal  " htmlFor="input_file_gst">Upload Gst</label>
              <div className="h-fit flex items-center  gap-2  appearance-none border rounded-lg w-full  text-[#c2c2c2]  leading-tight focus:outline-none focus:outline   cursor-pointer">
                <input required name="gstImageUrl" onChange={handleChange} accept="application/pdf" className="py-[7px] px-3" aria-describedby="file_input_help" id="input_file_gst" type="file" />
              </div>
            </div>
            <div>
              <label class="text-start block mb-2 text-sm text-gray-600 font-normal  " htmlFor="input_file_pan">Upload Pan</label>
              <div className="h-fit flex items-center  gap-2  appearance-none border rounded-lg w-full  text-[#c2c2c2]  leading-tight focus:outline-none focus:outline   cursor-pointer">
                <input required name="panImageUrl" onChange={handleChange} accept="application/pdf" className="py-[7px] px-3" aria-describedby="file_input_help" id="input_file_pan" type="file" />
              </div>
            </div>
          </div>
          <button disabled={isPending} type="submit" className="flex space-x-2 justify-center items-center mt-5 bg-blue-500 text-white py-2 px-4 rounded-full max-w-md mx-auto w-full">
            {isPending ? <Loader className='' /> : null} Register
          </button>
        </form>
      </div>
    </div>
  );
};
export default SignupForm


const IconInput = ({ icon: Icon, type = "text", placeholder, name, value, onChange }) => {
  const [showPassword, setShowPassword] = useState(false);
  const isPassword = type === "password";

  return (
    <div className="flex items-center gap-2 appearance-none border rounded-lg w-full py-2 px-3 text-gray-700 mb-2 leading-tight focus:outline-none focus:outline">
      {Icon && <Icon className="h-6 w-6 text-gray-300" />}

      <input
        className="w-full text-black outline-0 grow"
        type={isPassword && showPassword ? "text" : type}
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={onChange}
        required
      />

      {/* Show/Hide Password Toggle */}
      {isPassword && (
        <div onClick={() => setShowPassword((prev) => !prev)} className="cursor-pointer">
          {showPassword ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-6 h-6 text-gray-500"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-6 h-6 text-gray-500"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
              />
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
              />
            </svg>
          )}
        </div>
      )}
    </div>
  );
};


