'use client'
import React, { useState } from 'react'
import UploadImage from './UploadImage'
import UpArrow from './UpArrow'
import DownArrow from './DownArrow'
import Remove from './Remove'

const GridTwoImage = ({ index, initialValues, onDataChange }) => {
    const [formData, setFormData] = useState({
        image: initialValues?.image || '',
        image_public_id: initialValues?.image_public_id || '',
        image2: initialValues?.image2 || '',
        image2_public_id: initialValues?.image2_public_id || '',
        title: initialValues?.title || ''
    });

    const handleFieldChange = (field, value) => {
        let updatedFormData;
        if (field === 'image' || field === 'image2') {
            updatedFormData = {
                ...formData, [field]: value,
            };
        } else {
            updatedFormData = { ...formData, [field]: value };
        }
        setFormData(updatedFormData);
        onDataChange(updatedFormData);
    };

    return (
        <div
            id={`standard-image-with light text overlay-${index}}`}
            className='w-full flex flex-col border'>
            <div className='flex justify-between items-center border pl-4'>
                <div>
                    <h1 className='text-xl text-black font-semibold '>Standard Grid Two Image</h1>
                </div>
                <div className='flex' >
                    <UpArrow index={index} />
                    <DownArrow index={index} />
                    <Remove index={index} />
                </div>
            </div>
            <div className='my-2 px-5'>
                <label htmlFor="left-image-with-text-headline" className="text-left block mb-2 font-medium  text-gray-700 ">Title</label>
                <input
                    type="text"
                    id="left-image-with-text-headline"
                    className="block w-full text-gray-900 border border-gray-300 rounded-sm bg-gray-50 outline-none py-1 px-2 text-sm"
                    placeholder="Enter headline text"
                    maxLength={100}
                    required
                    value={formData?.title}
                    onChange={(e) => handleFieldChange('title', e.target.value)}
                />
            </div>
            <div className='mt-5 p-2 relative grid grid-cols-2 gap-5'>
                <UploadImage
                    id={`standard-grid-two-${index}`}
                    height={`h-[30rem]`}
                    name={`image`}
                    aspectRatio={500 / 500}
                    requiredRatio={`500/500`}
                    tolerance={0.1}
                    value={formData?.image}
                    onChange={handleFieldChange}
                />
                <UploadImage
                    id={`standard-grid-two-${index + 'feefer43'}`}
                    height={`h-[30rem]`}
                    name={`image2`}
                    aspectRatio={500 / 500}
                    requiredRatio={`500/500`}
                    tolerance={0.1}
                    value={formData?.image2}
                    onChange={handleFieldChange}
                />
            </div>


        </div>
    )
}

export default GridTwoImage;