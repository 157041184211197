
import ProductDescription from '../ProductDescription';
import CompanyLogo from '../CompanyLogo';
import BigBanner from '../BigBanner';
import BigHeadingAndDescription from '../BigHeadingAndDescription';
import GridTwoImage from '../GridTwoImage';
import BigVideo from '../BigVideo';

const componentRegistry = {
    ProductDescription,
    CompanyLogo,
    BigBanner,
    BigHeadingAndDescription,
    GridTwoImage,
    BigVideo
    // Add other components as needed
};

export default componentRegistry;
