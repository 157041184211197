import React, { useState, useEffect } from 'react'

import { User } from '../user/User'
import Pagination from '../../Pagination'
import TableRow from './TableRow'
import { useDebounce } from 'use-debounce';
import AddContentModal from './AddContentModal';
const getData = async (url) => {
    const res = await fetch(url)
    const data = await res.json()
    return data;
}
const ProductList = () => {
    const [page, setPage] = useState(1)
    const [limit, setlimit] = useState(10)
    const [search, setSearch] = useState('')
    const [totalItems, setTotalItems] = useState()
    const [data, setData] = useState([])
    const [searchQuery] = useDebounce(search, 1000);
    const [id, setId] = useState()
    useEffect(() => {
        let url = `${process.env.REACT_APP_URL}/v1/products/get-product-list?page=${page}&limit=${limit}`
        if (search) {
            url += `&search=${searchQuery}`
        }
        getData(url).then(data => {
            setData(data?.data)
            setTotalItems(data?.pagination?.totalItems)
        })
    }, [page, limit, searchQuery])
    return (
        <>
            <div className='py-10 pr-5 '>
                <div className="flex items-center justify-between py-3">
                    <p className="text-2xl">Products List </p>
                    <div className='flex flex-row space-x-5 items-center'>
                        <input className='w-80 py-2 px-4 rounded-lg border' type='search' value={search} onChange={e => setSearch(e.target.value)} placeholder='Search by product name' />
                        <User />
                    </div>


                </div>
                <section>
                    <div className="relative overflow-x-auto">
                        <table className="w-full text-left text-xs">
                            <thead className="bg-gray-100 text-xs uppercase text-[#666666]">
                                <tr>
                                    <th scope="col" className="px-2 py-3">
                                        Sr No.
                                    </th>
                                    <th scope="col" className="px-2 py-3">
                                        Image
                                    </th>
                                    <th scope="col" className="px-2 py-3">
                                        Name
                                    </th>
                                    <th scope="col" className="px-2 py-3">
                                        Product Sku
                                    </th>
                                    <th scope="col" className="px-2 py-3">
                                        Brand Name
                                    </th>
                                    <th scope="col" className="px-2 py-3">
                                        Category Name
                                    </th>
                                    <th scope="col" className="px-2 py-3">
                                        Action
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    data?.map((d, i) => (
                                        <TableRow key={d._id} data={d} i={i} setId={setId} />
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>

                    <div className="flex justify-between items-center p-5">
                        {/* Page Limit Selector */}
                        <div>
                            <select
                                value={limit}
                                onChange={e => setlimit(e.target.value)}
                                className='py-2 px-4 rounded-lg border'
                            >
                                <option value={10}>10</option>
                                <option value={20}>20</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                            </select>
                        </div>
                        <Pagination
                            currentPage={page}
                            totalItems={totalItems}
                            pageSize={limit}
                            setCurrentPage={setPage}
                        />
                    </div>
                </section>
            </div>
            {
                id &&
                <AddContentModal id={id} setId={setId} />
            }
        </>
    )
}

export default ProductList