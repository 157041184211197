import React, { useState, useEffect } from "react";
import axios from "axios";
import { getToken } from "../../../hook/getToken";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";

const ItemDetails = ({ goToNextTab }) => {
  const {id} = useParams()
  const preorder_session_id = localStorage.getItem("preorder_session_id");
  const sessionId = id || preorder_session_id;
  console.log("sessionId", sessionId,id,preorder_session_id);
  const [bulletPoints, setBulletPoints] = useState(["", ""]);
  const [itemName, setItemName] = useState("");
  const [itemNameHeading, setItemNameHeading] = useState("");
  const [skuCode, setSkuCode] = useState("");
  const [eanGtin, setEanGtin] = useState("");
  const [mrp, setMrp] = useState("");
  const [thresholdQty, setThresholdQty] = useState("");
  const [description, setDescription] = useState("");
  const [shortDescription, setShortDescription] = useState("");
  const [bannerImage, setBannerImage] = useState("");
  const [bannerImagePreview, setBannerImagePreview] = useState(null);
  const [images, setImages] = useState({
    main_img: null,
    img_2: null,
    img_3: null,
    img_4: null,
    img_5: null,
    img_6: null,
    img_7: null,
  });
  const [imagePreviews, setImagePreviews] = useState({
    main_img: null,
    img_2: null,
    img_3: null,
    img_4: null,
    img_5: null,
    img_6: null,
    img_7: null,
  });
  const [fundingGoal, setFundingGoal] = useState("");

  useEffect(() => {
    const fetchItemDetails = async () => {
     
      const accessToken = getToken();

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_URL}/v1/pre-order/get-item-details?preorder_session_id=${id}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        const data = response.data.response;

        setItemName(data.item_name || "");
        setItemNameHeading(data.item_name_heading || "");
        setSkuCode(data.product_sku || "");
        setEanGtin(data.ean_gtin || "");
        setMrp(data.mrp || "");
        setThresholdQty(data.threshold_qty || "");
        setDescription(data.description || "");
        setShortDescription(data.short_description || "");
        setBulletPoints(data.bullet_points || [""]);
        setFundingGoal(data.funding_goal || "");

        const imageData = {
          main_img: data.images.main_img || null,
          img_2: data.images.img_2 || null,
          img_3: data.images.img_3 || null,
          img_4: data.images.img_4 || null,
          img_5: data.images.img_5 || null,
          img_6: data.images.img_6 || null,
          img_7: data.images.img_7 || null,
        };

        setImages(imageData);
        setImagePreviews(imageData);

        setBannerImage(data.banner || null);
        setBannerImagePreview(data.banner || null);
      } catch (error) {
        console.error("Error fetching item details:", error);
      }
    };

    fetchItemDetails();
  }, []);

  const handleAddBulletPoint = () => {
    setBulletPoints([...bulletPoints, ""]);
  };

  const handleRemoveBulletPoint = (index) => {
    setBulletPoints(bulletPoints.filter((_, i) => i !== index));
  };

  const handleBulletPointChange = (index, value) => {
    const newBulletPoints = [...bulletPoints];
    newBulletPoints[index] = value;
    setBulletPoints(newBulletPoints);
  };

  const handleImageChange = (e, key) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      const imageDataUrl = reader.result;
      if (key === "banner_img") {
        setBannerImage(imageDataUrl);
        setBannerImagePreview(imageDataUrl);
      } else {
        setImages((prevImages) => ({
          ...prevImages,
          [key]: imageDataUrl,
        }));
        setImagePreviews((prevPreviews) => ({
          ...prevPreviews,
          [key]: imageDataUrl,
        }));
      }
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async ({ e, actionType, goToNextTab }) => {
    e.preventDefault();


    const accessToken = getToken();

    // Filter out null images
    const filteredImages = Object.fromEntries(
      Object.entries(images).filter(([key, value]) => value !== null)
    );

    const payload = {
      preorder_session_id: sessionId,
      item_name: itemName,
      item_name_heading: itemNameHeading,
      product_sku: skuCode,
      ean_gtin: eanGtin,
      mrp: mrp,
      description: description,
      short_description: shortDescription,
      bullet_points: bulletPoints,
      banner: bannerImage,
      images: filteredImages,
     
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/v1/pre-order/add-item-details`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      // toast.success(response.data);

      if (actionType === "next" && typeof goToNextTab === "function") {
        goToNextTab();
      }
      // Handle success (e.g., display a success message, redirect to another page, etc.)
    } catch (error) {
      console.error(error);
      // Handle error (e.g., display an error message)
    }
  };

  return (
    <div className="p-8 text-xs">
      <h1 className="text-xl font-semibold mb-6 text-left text-blue-600">
        Item Details
      </h1>
      <form className="space-y-4" onSubmit={handleSubmit}>
        <div className="grid grid-cols-2 gap-4">
          <div className="col-span-1">
            <label className="block font-semibold text-sm text-left">
              Item Name: *
            </label>
            <input
              type="text"
              className="w-full p-2 border border-gray-300 rounded text-sm h-8"
              placeholder="Item Name"
              value={itemName}
              onChange={(e) => setItemName(e.target.value)}
            />
          </div>
          <div className="col-span-1">
            <label className="block font-semibold text-sm text-left">
              Item Name Heading: *
            </label>
            <input
              type="text"
              className="w-full p-2 border border-gray-300 rounded text-sm h-8"
              placeholder="Item Name Heading"
              value={itemNameHeading}
              onChange={(e) => setItemNameHeading(e.target.value)}
            />
          </div>
        </div>
        <div className="grid grid-cols-4 gap-4">
          <div className="col-span-1">
            <label className="block font-semibold text-sm text-left">
              SKU Code:
            </label>
            <input
              type="text"
              className="w-full p-2 border border-gray-300 rounded text-sm h-8"
              placeholder="SKU Code"
              value={skuCode}
              onChange={(e) => setSkuCode(e.target.value)}
            />
          </div>
          <div className="col-span-1">
            <label className="block font-semibold text-sm text-left">
              EAN/GTIN:
            </label>
            <input
              type="text"
              className="w-full p-2 border border-gray-300 rounded text-sm h-8"
              placeholder="EAN/GTIN"
              value={eanGtin}
              onChange={(e) => setEanGtin(e.target.value)}
            />
          </div>
          <div className="col-span-1">
            <label className="block font-semibold text-sm text-left">
              MRP: *
            </label>
            <input
              type="text"
              className="w-full p-2 border border-gray-300 rounded text-sm h-8"
              placeholder="MRP"
              value={mrp}
              onChange={(e) => setMrp(e.target.value)}
            />
          </div>

        </div>
        <div className="grid grid-cols-2 gap-4">
         
        </div>
        <div>
          <label className="block font-semibold text-sm text-left">
            Description: *
          </label>
          <textarea
            className="w-full p-2 border border-gray-300 rounded text-sm"
            placeholder="Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          ></textarea>
        </div>
        <div>
          <label className="block font-semibold text-sm text-left">
            Short Description: *
          </label>
          <textarea
            className="w-full p-2 border border-gray-300 rounded text-sm"
            placeholder="Short Description"
            value={shortDescription}
            onChange={(e) => setShortDescription(e.target.value)}
          ></textarea>
        </div>
        <div>
          <label className="block font-semibold text-sm text-left">
            Banner Image:
          </label>
          <div
            className="w-full h-48 border border-gray-300 rounded flex items-center justify-center mb-2 cursor-pointer"
            onClick={() => document.getElementById("bannerImgInput").click()}
          >
            {bannerImagePreview ? (
              <img
                src={bannerImagePreview}
                alt="Banner Image Preview"
                className="w-full h-full object-cover"
              />
            ) : (
              <span className="text-gray-500 text-sm">
                Click to select image
              </span>
            )}
          </div>
          <input
            type="file"
            id="bannerImgInput"
            className="hidden"
            onChange={(e) => handleImageChange(e, "banner_img")}
          />
        </div>
        <div>
          <label className="block font-semibold text-sm text-left">
            Main Image:
          </label>
          <div
            className="w-24 h-24 border border-gray-300 rounded flex items-center justify-center mb-2 cursor-pointer"
            onClick={() => document.getElementById("mainImgInput").click()}
          >
            {imagePreviews.main_img ? (
              <img
                src={imagePreviews.main_img}
                alt="Main Image Preview"
                className="w-full h-full object-cover"
              />
            ) : (
              <span className="text-gray-500 text-sm">
                Click to select image
              </span>
            )}
          </div>
          <input
            type="file"
            id="mainImgInput"
            className="hidden"
            onChange={(e) => handleImageChange(e, "main_img")}
          />
        </div>
        <div className="grid grid-cols-6 gap-4">
          {[...Array(6)].map((_, index) => {
            const key = `img_${index + 2}`;
            return (
              <div key={index} className="col-span-1">
                <label className="block font-semibold text-sm text-left">
                  Image {index + 2}:
                </label>
                <div
                  className="w-24 h-24 border border-gray-300 rounded flex items-center justify-center mb-2 cursor-pointer"
                  onClick={() =>
                    document.getElementById(`imgInput${index + 2}`).click()
                  }
                >
                  {imagePreviews[key] ? (
                    <img
                      src={imagePreviews[key]}
                      alt={`Image ${index + 2} Preview`}
                      className="w-full h-full object-cover"
                    />
                  ) : (
                    <span className="text-gray-500 text-sm">
                      Click to select image
                    </span>
                  )}
                </div>
                <input
                  type="file"
                  id={`imgInput${index + 2}`}
                  className="hidden"
                  onChange={(e) => handleImageChange(e, key)}
                />
              </div>
            );
          })}
        </div>
        <div>
          <label className="block font-semibold text-sm text-left">
            Bullet Points: *
          </label>
          <div className="space-y-2">
            {bulletPoints.map((bulletPoint, index) => (
              <div key={index} className="flex items-center space-x-2">
                <input
                  type="text"
                  className="w-full p-2 border border-gray-300 rounded text-sm h-8"
                  placeholder="Descriptive point"
                  value={bulletPoint}
                  onChange={(e) =>
                    handleBulletPointChange(index, e.target.value)
                  }
                />
                <button
                  type="button"
                  className="text-red-500"
                  onClick={() => handleRemoveBulletPoint(index)}
                >
                  🗑️
                </button>
              </div>
            ))}
            <button
              type="button"
              className="text-blue-500 text-sm"
              onClick={handleAddBulletPoint}
            >
              + Add Bullet Point
            </button>
          </div>
        </div>
        <div className="col-span-2 text-right gap-4">
          <button
            type="button"
            className="px-4 py-2 bg-gray-500 text-white rounded text-sm"
          >
            Save as Draft
          </button>
          <button
            type="submit"
            className="ml-4 px-4 py-2 bg-blue-500 text-white rounded text-sm"
            onClick={(e) =>
              handleSubmit({ e, actionType: "next", goToNextTab })
            }
          >
            Save & Next
          </button>
        </div>
      </form>
    </div>
  );
};

export default ItemDetails;